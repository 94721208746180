/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/core'
import singleLine from '@waiterio/styles/singleLine'
import unselectable from '@waiterio/styles/unselectable'

export const modalOuter = css({
	display:'flex',
	alignItems: 'center',
	alignContent: 'center',
	justifyContent: 'center',
	position: 'fixed',
	transform: 'translate(0,0)',
	width: 'auto',
	left: 0,
	right: 0,
	height: 'auto',
	top: 0,
	bottom: 0,
	zIndex: 9990,
	background: '#transparent',
	padding: '0 14.5px',
})

export const modal = css(
	unselectable,
	{
		boxSizing: 'border-box',
		width: '280px',
		height: 'auto',
		maxHeight: '100%',
		overflowX: 'hidden',
		overflowY: 'auto',
		position: 'relative',
		zIndex: 9999,
		background: '#fefefe',
		'& div, & input, & button': {
			minHeight: '48px',
		},
		'& textarea': {
			width: '280px',
			minHeight:'96px',
		},
		'& .title': [
			singleLine,
			{
				textAlign: 'center',
				lineHeight: '48px',
				padding: '0 14.5px',
				color: 'white',
				background: 'var(--color-primary)',
			},
		],
		'& .progress-title': [
			singleLine,
			{
				textAlign: 'left',
				lineHeight: '48px',
				padding: '0 14.5px',
				color: 'var(--color-primary)',
				borderBottom: '1.5px solid var(--color-primary)',
			},
		],
		'& .pick-one-div': [
			singleLine,
			{
				flexGrow: 1,
				lineHeight: '48px',
				border: '0px',
				padding: '0 14.5px',
				borderBottom: '1px solid #e4e4e4',
				cursor:'pointer',
				textAlign: 'center',
			},
		],
		'@media (min-width: 993px)': {
			'& .pick-one-div:hover': {
				background: 'rgba(51, 181, 229, 0.3)',
			},
		},
		'& input, & button': {
			flexGrow: 1,
			minWidth: 0,
			outline: 0,
			paddingLeft: '14.5px',
			paddingRight: '14.5px',
			border: 0,
		},
		'& input[type="submit"], & button': {
			width: '100%',
			borderTop: '1px solid #e4e4e4',
		},
	},
)

export const backdrop = css({
	display: 'block',
	position: 'absolute',
	width: 'auto',
	left: 0,
	right: 0,
	height: 'auto',
	top: 0,
	bottom: 0,
	boxSizing: 'border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; -o-box-sizing: border-box',
	zIndex: 9995, /* display between modal container and modal window */
	backgroundColor: ['rgb(0,0,0)', 'rgba(0,0,0,0.4)'],
})

export default function Modal ({ children, close }) {

	const _close = event => {

		if (event) {
			event.preventDefault()
		}

		if (close) {
			close()
		}

	}

	return (

		<div css={modalOuter}>
			<div css={modal}>{children}</div>
			<div css={backdrop} onClick={_close} data-automation="close-modal" />
		</div>

	)

}
