import { css } from '@emotion/core'
import elevated from './elevated'

export default css([
	elevated,
	{
		label: 'card',
		backgroundColor: 'white',
		display: 'inherit',
	},
])
