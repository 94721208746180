import { getContext } from '@waiterio/context'
import translationSelector from '@waiterio/builder/selectors/translationSelector'

export default function (key, locale = 'en') {

	const translation = translationSelector(getContext(), { locale })

	return translation && translation[key] ? translation[key] : key

}
