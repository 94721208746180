/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import getRestaurant from '@waiterio/builder/getRestaurant'
import translate from '@waiterio/builder/translate'
import { connect, produce } from '@waiterio/context'
import clearSession from '@waiterio/builder/session/clearSession'

import AppBar from '@waiterio/components/AppBar'
import Dropdown from '@waiterio/builder/components/Dropdown'
import Card from '@waiterio/components/Card'
import IconBack from '@waiterio/components/IconBack'
import IconAdd from '@waiterio/components/IconAdd'
import FieldStringDialog from '@waiterio/builder/dialogs/FieldStringDialog'
import { toastError } from '@waiterio/builder/services/Toaster'

import updateRestaurant from '@waiterio/builder/services/updateRestaurant'
import getImageFromEvent from '@waiterio/shared/getImageFromEvent'
import updateCoverOfRestaurant from '@waiterio/api-client/updateCoverOfRestaurant'
// import getBrowserHistory from '@waiterio/builder/getBrowserHistory'

import LayoutComponent from '../components/LayoutComponent'


const errorMessage = css({
	display:'flex',
	flex:1,
	alignItems:'center',
	justifyContent:'center',
	height:160,
	fontSize:36,
	color:'red',
	padding: 36,
})

const fieldContainer = css({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	cursor: 'pointer',
	padding: '0 16px',
	overflow: 'hidden',
	':hover': {
		background: 'var(--color-primary-lightest)',
	},
})

const fieldValue = css({
	textAlign: 'right',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: 300,
	width: '100%',
})

class InfoPage extends React.Component {

	componentDidMount = async () => {

		try {

			let { restaurant } = this.props

			if (!restaurant) {

				let { restaurantId } = this.props

				this.props.produce(draft => {
					draft.loading = true
				})

				const restaurant = await getRestaurant(restaurantId)

				produce(draft => {
					draft.data.restaurant = restaurant
				})

				this.props.produce(draft => {
					draft.loading = false
				})

			}


		} catch (error) {

			this.props.produce(draft => {
				draft.loading = false
				draft.error = error && error.message
			})

		}

	}

	showFieldStringDialog = (label, value) => {
		this.props.produce(draft => {
			draft.fieldStringDialog = {
				label,
				value,
			}
		})
	}

	hideFieldStringDialog = () => {
		this.props.produce(draft => { delete draft.fieldStringDialog })
	}

	saveFieldByLabelAndValue = (fieldLabel, fieldValue) => {

		let hasRestaurantChanged = false
		let { restaurant } = this.props

		restaurant = restaurant.set(fieldLabel, fieldValue)

		if (restaurant[fieldLabel] !== this.props.restaurant[fieldLabel]) {

			hasRestaurantChanged = true

		}

		if (hasRestaurantChanged) {

			produce(draft => {
				draft.data.restaurant = restaurant
			})

			this.changeRestaurant(restaurant, this.props.restaurant)

		}

		this.hideFieldStringDialog()

	}

	changeRestaurant = (restaurant, oldRestaurant) => {

		updateRestaurant(restaurant)
			.catch(error => {

				produce(draft => {
					draft.data.restaurant = oldRestaurant
				})

				toastError(error)

			})

	}

	onClickCoverPhoto = event => {
		document.getElementById('uploadCoverPhotoInput').click()
	}

	changeCoverPhoto = async event => {

		try {
			const { _id } = this.props.restaurant

			const image = await getImageFromEvent(event)
			const restaurantWithUpdatedCoverUrl = await updateCoverOfRestaurant(_id, image)

			produce(draft => {
				draft.data.restaurant = restaurantWithUpdatedCoverUrl
			})

		} catch (error) {
			console.error(error)
			toastError(error)
		}

	}

	render () {

		const {
			loading,
			error,
			restaurant = {},
			fieldStringDialog,
		} = this.props

		const {
			_id,
			name,
			vanityId,
			// facebookId,
			// instagramId,
			// googlePlaceId,
			theme,
			coverUrl,
		} = restaurant

		const themeColorValues = ['aqua', 'blue', 'brown', 'gray', 'green', 'orange', 'red', 'yellow']
		const themeColorLabels = themeColorValues.map(translate)

		return <>

			{loading && <div css={{marginBottom:30}} className="loader" />}

			{!loading && restaurant &&
				<LayoutComponent>
					<div css={{display:'flex',flexDirection:'column',background:'#dce1e3',height:'100%', overflow: 'auto'}}>

						<AppBar>
							<Link
								onClick={() => clearSession()}
								to="/builder/login"
								css={{
									display: 'flex',
									alignItems: 'center',
									textDecoration: 'none',
									padding: 16,
									':hover': {
										background: 'var(--color-primary-light)',
										color: 'white',
									},
								}}
							>
								<IconBack
									css={{
										fill: 'white',
										margin: '0 16px',
									}}
								/>
								<div
									css={{
										marginRight: 16,
										textTransform: 'capitalize',
									}}
								>
									{translate('Website builder')}
								</div>
							</Link>
						</AppBar>

						<div css={{padding:'0 16px'}}>
							<Card
								css={{
									maxWidth: 500,
									width: '100%',
									margin:'16px auto',
									lineHeight: '48px',
									fontSize: 18,
								}}
							>
								<div css={{padding:'0 16px',fontWeight:'bold'}}>{translate('Info')}</div>

								<div css={{width:'100%',height:1,backgroundColor:'#e4e4e4'}} />

								<div
									onClick={() => this.showFieldStringDialog('name', name)}
									css={fieldContainer}
								>
									<div css={{marginRight: 16}}>{translate('name')}</div>
									<div css={fieldValue}>{name}</div>
								</div>

								<div
									onClick={() => this.showFieldStringDialog('vanityId', vanityId || _id)}
									css={fieldContainer}
								>
									<div css={{marginRight: 16}}>{translate('website')}</div>
									<div css={fieldValue}>{vanityId || _id }.waiterio.com</div>
								</div>
								{/* <div
									onClick={() => this.showFieldStringDialog('Facebook', facebookId)}
									css={fieldContainer}
								>
									<div css={{marginRight: 16}}>{translate('Facebook')}</div>
									<div css={fieldValue}>{facebookId ? `facebook.com/${facebookId}` : ''}</div>
								</div> */}
								{/* <div
									onClick={() => this.showFieldStringDialog('Instagram', instagramId)}
									css={fieldContainer}
								>
									<div css={{marginRight: 16}}>{translate('Instagram')}</div>
									<div css={fieldValue}>{instagramId ? `instagram.com/${instagramId}` : ''}</div>
								</div> */}
								{/* <div
									onClick={() => getBrowserHistory().push('/builder/find-on-google-maps')}
									css={fieldContainer}
								>
									<div css={{marginRight: 16}}>{translate('Google Maps')}</div>
									<div css={fieldValue}>{googlePlaceId}</div>
								</div> */}

								<div
									onClick={this.onClickCoverPhoto}
									css={{
										cursor: 'pointer',
										':hover': {
											background: 'var(--color-primary-lightest)',
										},
									}}
								>

									<div css={{flexGrow:1,display:'flex',flexDirection:'column'}}>
										<div css={{display:'flex'}}>
											<div
												css={{flex: 1, paddingLeft: 16, lineHeight:'48px'}}
											>
												{translate('Cover Photo')}
											</div>

											{!coverUrl &&
												<IconAdd css={{margin: 'auto 16px', fill:'#626262'}} />
											}

										</div>

										{coverUrl &&
											<img
												src={coverUrl}
												css={{width:'100%',objectFit:'contain'}}
											/>
										}

										<input
											id="uploadCoverPhotoInput"
											type="file"
											accept="image/*"
											capture
											css={{display:'none'}}
											onChange={this.changeCoverPhoto}
										/>

									</div>
								</div>

								<Dropdown
									name={translate('Theme').toLowerCase()}
									onSelect={value => this.saveFieldByLabelAndValue('theme', value)}
									currentValue={theme || 'gray'}
									labels={themeColorLabels}
									values={themeColorValues}
								/>


								{/* eslint-disable react/jsx-no-target-blank */}
								<a
									href={`https://${vanityId || _id }.waiterio.com`}
									target="_blank"
									css={{
										background: 'var(--color-secondary)',
										color: 'white',
										display: 'block',
										lineHeight: '48px',
										fontWeight: 'bold',
										textAlign: 'center',
										textDecoration: 'none',
										textTransform: 'uppercase',
										':hover' : {
											background: 'var(--color-secondary-light)',
											color: 'white',
										},
									}}
								>
									{translate('Visit website')}
								</a>
								{/* eslint-enable react/jsx-no-target-blank */}
							</Card>
						</div>

						{fieldStringDialog &&
							<FieldStringDialog
								label={fieldStringDialog.label}
								value={fieldStringDialog.value}
								close={() => this.hideFieldStringDialog()}
								onSave={this.saveFieldByLabelAndValue}
							/>
						}

					</div>

					{error &&
						<div css={errorMessage}>
							{error}
						</div>
					}

				</LayoutComponent>
			}
		</>
	}
}

export default connect((context, props, produce) => {

	const restaurantId = context.session.currentRestaurantId
	const { restaurant } = context.data
	let { fieldStringDialog = false, loading, error } = context.pages.info

	return {
		restaurant,
		restaurantId,
		fieldStringDialog,
		loading,
		error,
		produce: produce(['pages', 'info']),
	}

})(InfoPage)
