import fetch from 'isomorphic-fetch'
import failure from '@waiterio/api-client/failure'

export default function httpWithoutHeaders (url, customFailure) {

	return fetch(url).then(function (response) {

		if (!response.ok) {

			return response.json().then(error => {

				throw error

			})

		} else {

			return response.json()

		}

	}).catch(customFailure || failure)

}
