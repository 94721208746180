import updateRestaurantRest from '@waiterio/api-client/updateRestaurant'

export default function (newRestaurant) {

	newRestaurant = newRestaurant.set('lastEditTime', new Date().getTime())

	if (newRestaurant) {

		return updateRestaurantRest(newRestaurant).catch(error => {

			throw error

		})

	}

}
