/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import translate from '@waiterio/builder/translate'
import Modal from '@waiterio/components/Modal'

export default ({ close, title, message, confirm }) => {

	if (message && message.trim().length === 0) {
		message = null
	}

	return (

		<Modal close={close}>
			<div className="progress-title">{title || translate('Error')}</div>
			<div css={{display:'flex',color:'#ababab',minHeight:80}}>
				<div css={{alignSelf:'center',width:'100%',textAlign:'center',whiteSpace:'pre-line',padding:'14.5px',overflow:'hidden',wordWrap:'break-word',userSelect:'text',cursor:'text'}}>{message || translate('An error occurred')}</div>
			</div>
			<div
				css={{
					fontSize: 18,
					fontWeight: '400',
					backgroundColor: 'var(--color-primary)',
					color: 'white',
					padding: '10px 50px',
					cursor: 'pointer',
					textAlign: 'center',
					lineHeight: '28px',
					'@media (min-width: 993px)': {
						':hover': {
							backgroundColor: 'var(--color-primary-lighter)',
						},
					},
				}}
				onClick={event => { event.preventDefault(); close() }}
			>
				{confirm || translate('Okay')}
			</div>
		</Modal>

	)

}
