import React, { PureComponent } from 'react'

export default class IconClose extends PureComponent {

	render = () => {

		const {className, width = 24, height = 24, onClick, style} = this.props

		return (
			<svg className={className} style={style} width={width} height={height} viewBox="0 0 24 24" onClick={onClick}>
				<path d="M19.008 6.408l-1.416-1.416-5.592 5.592-5.592-5.592-1.416 1.416 5.592 5.592-5.592 5.592 1.416 1.416 5.592-5.592 5.592 5.592 1.416-1.416-5.592-5.592z" />
			</svg>
		)

	}

}
