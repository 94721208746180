const IS_BROWSER = require('./IS_BROWSER')
const IS_NODE = require('./IS_NODE')

let IS_SCREENSHOTS = false

if (IS_BROWSER && window.WAITERIO_SCREENSHOTS && window.WAITERIO_SCREENSHOTS !== '{{{WAITERIO_SCREENSHOTS}}}') {

	IS_SCREENSHOTS = window.WAITERIO_SCREENSHOTS

} else if (IS_NODE && process.env.WAITERIO_SCREENSHOTS) {

	IS_SCREENSHOTS = process.env.WAITERIO_SCREENSHOTS

}

// if (IS_STAGING || IS_DEVELOPMENT) {
// 	console.debug('IS_SCREENSHOTS = ' + IS_SCREENSHOTS)
// }

module.exports = IS_SCREENSHOTS
