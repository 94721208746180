export default `

	:root {
		--color-base: #626262;

		--color-primary: #626262;

		--color-neutral-min: white;
		--color-neutral-max: black;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	body, html {
		height: 100%;
		width: 100%;
		margin: 0;
		outline: 0;
		color: var(--color-base);
		fill: var(--color-base);
		-webkit-text-size-adjust: 100%;
		-webkit-tap-highlight-color: var(--color-primary);
	}

	body, html, button {
		font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Fira Sans','Droid Sans','Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
	}

	a {
		color: inherit;
	}

	a:hover {
		color: black;
	}

	img {
		width: 100%;
	}

	#root:not(:empty) {
		width: 100%;
		height: 100%;
	}

	.loader:empty {

		top: 50%;
		margin: 0px auto;
		transform: translateY(-50%);

		border-radius: 50%;
		width: 7em;
		height: 7em;

		font-size: 5px;
		position: relative;
		text-indent: -9999em;
		border-top: 1.1em solid rgba(0, 0, 0, 0.2);
		border-right: 1.1em solid rgba(0, 0, 0, 0.2);
		border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
		border-left: 1.1em solid var(--color-primary);
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: rotation 1.1s infinite linear;
		animation: rotation 1.1s infinite linear;
	}

	@-webkit-keyframes rotation {

		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}

	}

	@keyframes rotation {

		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}

	}

	@keyframes swingIconPapillon {

		from {
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		to {
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}

	}

	#android_toast_container {
		width: 100%;
		position: fixed;
		bottom: 12%;
		left: 0px;
		z-index: 1000;
	}

	#android_toast {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		background-color: #303437;
		color: #f5f5f5;
		font-size: 18px;
		padding: 5px;
		border-radius: 2px;
		-webkit-border-radius: 2px;
		opacity: 0.95;
		-webkit-box-shadow: 1px 2px 2px 1px rgba(34,34,34,1);
		box-shadow: 1px 2px 2px 1px rgba(34,34,34,1);
		text-align: center;
	}

	#android_toast_em {
		color: #51b4d2;
		font-weight: 700;
		font-style: normal;
	}

	.android_toast_top {
		top: 12%;
		bottom: auto!important;
	}

	.android_toast_fadein {
		animation: android_toast_fadein 1s ease;
		-webkit-animation: android_toast_fadein 1s ease;
	}

	.android_toast_fadeout {
		animation: android_toast_fadeout 1s ease;
		-webkit-animation: android_toast_fadeout 1s ease;
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;
	}

	@keyframes android_toast_fadein {

		from {
			opacity: 0;
			-webkit-opacity: 0;
		}

		to {
			opacity: 0.95;
			-webkit-opacity: 0.95;
		}

	}

	@-webkit-keyframes android_toast_fadein {

		from {
			opacity: 0;
			-webkit-opacity: 0;
		}

		to {
			opacity: 0.95;
			-webkit-opacity: 0.95;
		}

	}

	@keyframes android_toast_fadeout {

		from {
			opacity: 0.95;
			-webkit-opacity: 0.95;
		}

		to {
			opacity: 0;
			-webkit-opacity: 0;
		}

	}

	@-webkit-keyframes android_toast_fadeout {

		from {
			opacity: 0.95;
			-webkit-opacity: 0.95;
		}

		to {
			opacity: 0;
			-webkit-opacity: 0
		}

	}

`
