import React, { PureComponent } from 'react'

export default class IconAdd extends PureComponent {

	render = () => {

		const {className, width = 24, height = 24, onClick, style} = this.props
		const dataAutomation = this.props['data-automation'] || 'icon-add'

		return (
			<svg className={className} style={style} width={width} height={height} viewBox="0 0 24 24" onClick={onClick} data-automation={dataAutomation}>
				<path d="M19.008 13.008h-6v6h-2.016v-6h-6v-2.016h6v-6h2.016v6h6v2.016z" />
			</svg>
		)

	}

}
