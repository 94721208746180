import React, { PureComponent } from 'react'

export default class IconBack extends PureComponent {

	render = () => {

		const {className, width = 24, height = 24, onClick, style} = this.props

		return (
			<svg className={className} style={style} width={width} height={height} viewBox="0 0 24 24" onClick={onClick} data-automation="icon-back">
				<path d="M19.992 10.992h-12.168l5.592-5.568-1.416-1.416-7.992 7.992 7.992 7.992 1.416-1.416-5.592-5.568h12.168v-2.016z" />
			</svg>
		)

	}

}
