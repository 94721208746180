/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import Modal from '@waiterio/components/Modal'
import IconPapillon from '@waiterio/components/IconPapillon'

// const loader = css({
// 	top: '50%',
// 	margin: '0 auto',
//
// 	borderRadius: '50%',
// 	width: '5em',
// 	height: '5em',
//
// 	fontSize: '5px',
// 	position: 'relative',
// 	textIndent: '-9999em',
// 	borderTop: '1.1em solid rgba(0, 0, 0, 0.2)',
// 	borderRight: '1.1em solid rgba(0, 0, 0, 0.2)',
// 	borderBottom: '1.1em solid rgba(0, 0, 0, 0.2)',
// 	borderLeft: '1.1em solid var(--color-primary)',
// 	transform: 'translateZ(0)',
// 	animation: 'rotation 1.1s infinite linear',
// })

const swingingIconPapillon = css({
	fill: 'var(--color-primary)',
	transform: 'rotate(0deg)',
	animationName: 'swingIconPapillon',
	animationDuration: '1.1s',
	animationTimingFunction: 'ease-in-out',
	animationDelay: '0s',
	animationIterationCount: 'infinite',
	animationDirection: 'alternate',
})


export default ({ message, title}) => (

	<Modal>
		<div className="progress-title">{title}</div>
		<div css={{display:'flex',color:'#626262',minHeight:80}}>
			<IconPapillon css={[swingingIconPapillon,{alignSelf:'center',height:40,width:40,minWidth:40,margin:20}]} />
			<div css={{display:'flex',alignSelf:'center',alignItems:'center',textAlign:'left',width:200,whiteSpace:'pre-line',padding:'14.5px',paddingLeft:0}}>
				<span>{message}</span>
			</div>
		</div>
	</Modal>

)
