import { produce } from '@waiterio/context'
import setCurrentRestaurantId from '@waiterio/builder/session/setCurrentRestaurantId'
import setCurrentUser from '@waiterio/builder/session/setCurrentUser'

export default function storeNewSession (loginData) {

	const { restaurantId } = loginData.roles[0]
	const { user } = loginData

	setCurrentRestaurantId(restaurantId)
	setCurrentUser(user)

	produce(draft => {
		draft.data = {}
		draft.session.currentUser = user
		draft.session.currentRestaurantId = restaurantId
		draft.session.rehydrated = true
	})

}
