/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { connect } from '@waiterio/context'
import { Link } from 'react-router-dom'
import normalizeEmail from '@waiterio/shared/normalizeEmail'
import translate from '@waiterio/builder/translate'
import getBrowserHistory from '@waiterio/builder/getBrowserHistory'
import * as loginManager from '@waiterio/builder/services/LoginManager'
import storeNewSession from '@waiterio/builder/session/storeNewSession'

import AppBar from '@waiterio/components/AppBar'
import IconBack from '@waiterio/components/IconBack'
import LoadingDialog from '@waiterio/builder/dialogs/LoadingDialog'
import ErrorDialog from '@waiterio/builder/dialogs/ErrorDialog'
import LayoutComponent from '@waiterio/builder/components/LayoutComponent'

const formField = css({
	fontSize: 18,
	width: '100%',
	paddingRight: 8,
	height: 48,
	outline: 'none',
	color: 'var(--color-base)',
	border: 0,
	borderRadius: 0,
	textAlign: 'right',
})

const formLabel = css({
	fontSize: 18,
	paddingLeft: 8,
	paddingRight: 8,
	display: 'flex',
	alignItems: 'center',
})

class LoginPage extends React.Component {

	state = {
		isEmailFocused: false,
		isPasswordFocused: false,
	}

	onInputFocus = type => {

		this.setState(previousState => {
			return { [type]: !previousState[type] }
		})

	}

	onChangeInput = (type, event) => {

		const inputValue = event.target.value

		this.props.produce(draft => {
			draft[type] = inputValue
		})

	}

	showError = error => {

		this.props.produce(draft => {
			draft.logging = false
			draft.error = error
		})

	}

	hideError = () => {
		this.props.produce(draft => { draft.error = null })
	}

	showLogging = () => {
		this.props.produce(draft => {
			draft.logging = true
		})
	}

	onLoginWithEmail = event => {

		event.preventDefault()

		this.showLogging()

		let { email, password } = this.props

		email = normalizeEmail(email)

		loginManager.loginWithEmail(email, password)
			.then(this.onLoginSucceed)
			.catch(error => {
				this.showError(error.toString())
			})

	}

	onLoginSucceed = loginResponse => {

		this.props.produce(draft => {
			draft.logging = false
		})

		storeNewSession(loginResponse)

		getBrowserHistory().push('/builder/info')

	}

	render () {

		const { email, password, logging, error } = this.props

		return (
			<LayoutComponent>

				<AppBar>
					<Link
						to="/builder"
						css={{
							display: 'flex',
							alignItems: 'center',
							textDecoration: 'none',
							':hover': {
								color: 'white',
								background: 'var(--color-primary-light)',
							},
						}}
					>
						<IconBack
							css={{
								fill: 'white',
								margin: '0 16px',
							}}
						/>
						<div
							css={{
								marginRight: 16,
								fontSize: 18,
							}}
						>
							{translate('Login')}
						</div>
					</Link>
				</AppBar>

				<div css={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', backgroundColor: 'rgb(220, 225, 227)'}}>

					<div
						css={{
							maxWidth: 400,
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							marginTop: 14,
							paddingLeft: 14,
							paddingRight: 14,
							border: 0,
						}}
					>
						<form onSubmit={this.onLoginWithEmail} css={{width: '100%', backgroundColor: 'white'}}>
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="emailInput"
									css={formLabel}
								>
									{translate('email')}
								</label>
								<input
									id="emailInput"
									css={formField}
									type="email"
									value={email}
									onChange={event => this.onChangeInput('email', event)}
									required
								/>
							</div>
							<div css={{height: 1, marginLeft: 8, marginRight: 8, backgroundColor: '#e8e8e8'}} />
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="passwordInput"
									css={formLabel}
								>
									{translate('password')}
								</label>
								<input
									id="passwordInput"
									css={formField}
									type="password"
									value={password}
									onChange={event => this.onChangeInput('password', event)}
									required
								/>
							</div>

							<button
								type="submit"
								css={{
									padding: 14,
									border: 0,
									margin: 0,
									width: '100%',
									fontSize: 18,
									fontWeight: 'bold',
									backgroundColor: 'var(--color-secondary)',
									color: 'white',
									cursor: 'pointer',
									textTransform: 'uppercase',
									':hover': {
										background: 'var(--color-secondary-light)',
									},
								}}
							>
								{translate('Login')}
							</button>

						</form>

						<Link
							to="/builder/signup"
							css={{
								fontSize: 14,
								fontWeight: 'bold',
								width: '100%',
								padding: 14,
								textAlign: 'center',
								cursor: 'pointer',
								textDecoration: 'none',
								textTransform: 'uppercase',
								':hover': {
									color: 'var(--color-base)',
									backgroundColor: 'var(--color-primary-lightest)',
								},
							}}
						>
							{translate('Sign up')}
						</Link>

					</div>

					{logging &&
						<LoadingDialog
							title={translate('Logging in')}
							message={translate('logging in waiterio')}
						/>
					}

					{error &&
						<ErrorDialog
							title={translate('Error')}
							message={
								error || translate('An error occurred')
							}
							close={this.hideError}
						/>
					}


				</div>
			</LayoutComponent>
		)

	}

}

export default connect((context, props, produce) => {

	const {
		email = '',
		password = '',
		logging = false,
		error = false,
	} = context.pages.login

	return {
		email,
		password,
		logging,
		error,
		produce: produce(['pages', 'login']),
	}

})(LoginPage)
