/** @jsx jsx */
import React, { PureComponent } from 'react'
import { css, jsx } from '@emotion/core'

const clickable = css`
	label: clickable;
	user-select: none;
	cursor: default;
	outline: none;

	@media (min-width: 993px) {
		&:not([disabled]):hover,
		&:not([disabled]):active {
			background-color: #f1edfe;
			cursor: pointer;
		}
	}
`

const dropdown = css({
	width: '100%',
})

const wrapper = css`
	display: inline-block;
	position: relative;
	width: 100%;
`

const toggler = css`
	cursor: pointer;
	width: 100%;
	line-height: 48px;
	padding: 0 14.5px;
	box-sizing: border-box;
	${clickable}
	display: flex;
`

const content = css`
	position: absolute;
	overflow: auto;
	top: 100%;
	width: 100%;
	z-index: 1;
	border: 1px solid #D8CCFE;
	background-color: white;
	width: 100%;
	margin-bottom:14.5px;
`

const item = css`
	display: block;
	position: relative;
	line-height: 48px;
	padding: 0 14.5px;
	width: 100%;
	cursor: pointer;
	box-sizing: border-box;
	color: #333333;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-bottom-color: #e8e8e8;
	${clickable};
	:last-of-type {
		border-bottom-style: none;
	}
`

export default class Dropdown extends PureComponent {

	state = {
		isVisible: false,
	}

	componentDidMount = () => {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount = () => {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	onSelect (value) {

		this.setState({ isVisible: false })

		if (this.props.onSelect) {
			this.props.onSelect(value)
		}

	}

	setWrapperRef = element => {
		this.wrapperRef = element
	}

	toggleDropdown = () => {

		const { isVisible } = this.state

		this.setState({ isVisible: !isVisible })

	}

	handleClickOutside = event => {

		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ isVisible: false })
		}

	}

	handleBlur = () => {

		this.setState({
			isVisible: false,
		})

	}

	renderDropdown = () => {

		const { currentValue, labels, name, values, style, tabIndex } = this.props
		const dataAutomation = this.props['data-automation'] || `${name}-dropdown`.toLowerCase()
		const { isVisible } = this.state
		const indexCurrentValue = values.findIndex(element => { return element === currentValue })
		const currentLabel = labels && labels.length > indexCurrentValue ? labels[indexCurrentValue] : currentValue

		return (
			<div css={wrapper} tabIndex={tabIndex} ref={this.setWrapperRef} onBlur={this.handleBlur}>
				<div css={toggler} onClick={this.toggleDropdown} data-automation={dataAutomation}>
					{name && <div css={style}>{name}</div>}
					<div css={{flexGrow:1,textAlign:name ? 'right' : 'left'}} data-automation={`${dataAutomation}-${currentLabel}`.toLowerCase()}>{currentLabel}</div>
				</div>
				{isVisible &&
					<div css={content}>
						{values && values.map((value, index) => {

							return (
								value !== currentValue &&
								<div id={value.toString()} css={[item, {textAlign:name ? 'right' : 'left'}]} key={value.toString()} onClick={event => { event.preventDefault(); this.onSelect(value) }} data-automation={`${dataAutomation}-${(labels && labels.length > index ? labels[index] : value)}`.toLowerCase()}>{labels && labels.length > index ? labels[index] : value}</div>
							)
						})}

					</div>
				}
			</div>
		)
	}


	render = () =>

		<div css={dropdown}>
			{this.renderDropdown()}
		</div>

}
