import Immutable from 'seamless-immutable'
import PaymentMethod from './PaymentMethod'
import OpeningHours from './OpeningHours'
import UUID from './UUID'

function Restaurant (source) {

	if (source instanceof Restaurant) {

		return source

	} else {

		source && Object.keys(source).map(function (key) {
			this[key] = source[key]
		}.bind(this))

		if (!this._id) {
			this._id = UUID.generate()
		}

		if (!this.shortId) {
			this.shortId = UUID.generateRestaurantShortId()
		}

		if (!this.creationTime) {
			this.creationTime = new Date().getTime()
		}

		if (!this.lastEditTime) {
			this.lastEditTime = this.creationTime
		}

		if (!this.paymentMethods) {
			this.paymentMethods = []
		}

		if (this.paymentMethods) {

			for (let i = 0; i < this.paymentMethods.length; i += 1) {
				this.paymentMethods[i] = new PaymentMethod(this.paymentMethods[i])
			}

		}

		if (!this.verifiedFields) {
			this.verifiedFields = []
		}

		if (!this.openingHours) {
			this.openingHours = []
		}

		if (this.openingHours) {

			for (let i = 0; i < this.openingHours.length; i += 1) {
				this.openingHours[i] = new OpeningHours(this.openingHours[i])
			}

		}

		return Immutable(this, {prototype: Restaurant.prototype})

	}

}

Restaurant.prototype.constructor = Restaurant

Restaurant.Type = {
	CASUAL_DINING: 'Casual Dining',
	FINE_DINING: 'Fine Dining',
	BUFFET: 'Buffet',
	HOTEL: 'Hotel',
	BAR_PUB: 'Bar/Pub',
	NIGHTCLUB: 'Nightclub',
	COUNTER_SERVICE: 'Counter Service',
	FAST_FOOD: 'Fast Food',
	COFFEE_SHOP: 'Coffee Shop',
	FOOD_TRUCK: 'Food Truck',
	DELIVERY: 'Delivery',
	SHISHA: 'Shisha',
	CAFETERIA: 'Cafeteria',
	DELI_GROCERY: 'Deli/Grocery',
	BAKERY: 'Bakery',
	CATERING: 'Catering',
	FESTIVAL: 'Festival',
}

Restaurant.prototype.addOrUpdatePaymentMethod = function (paymentMethod) {

	let index = this.getPaymentMethodIndex(paymentMethod.id)

	if (!index && index !== 0) {
		index = this.paymentMethods.length
	}

	return this.setIn(['paymentMethods', index], new PaymentMethod(paymentMethod))

}

Restaurant.prototype.getPaymentMethodIndex = function (paymentMethodId) {

	const paymentMethodIndex = this.paymentMethods.findIndex(paymentMethod => paymentMethod.id === paymentMethodId)

	return paymentMethodIndex >= 0 ? paymentMethodIndex : null

}

Restaurant.prototype.removePaymentMethod = function (paymentMethod) {

	return this.removePaymentMethodById(paymentMethod.id)

}

Restaurant.prototype.removePaymentMethodById = function (paymentMethodId) {

	return this.set('paymentMethods', this.paymentMethods.filter(paymentMethod => paymentMethod.id !== paymentMethodId))

}

Restaurant.prototype.getOpeningHoursIndex = function (openingHoursId) {

	const openingHoursIndex = this.openingHours.findIndex(openingHours => openingHours.id === openingHoursId)

	return openingHoursIndex >= 0 ? openingHoursIndex : null

}

Restaurant.prototype.removeOpeningHours = function (openingHours) {

	return this.removeOpeningHoursById(openingHours.id)

}

Restaurant.prototype.removeOpeningHoursById = function (openingHoursId) {

	return this.set('openingHours', this.openingHours.filter(openingHours => openingHours.id !== openingHoursId))

}

Restaurant.prototype.addOrUpdateOpeningHours = function (openingHours) {

	let index = this.getOpeningHoursIndex(openingHours.id)

	if (!index && index !== 0) {
		index = this.openingHours.length
	}

	return this.setIn(['openingHours', index], new OpeningHours(openingHours))

}

Restaurant.prototype.getOpeningHours = function (openingHoursId) {

	let openingHours = null

	if (this.openingHours) {

		let i = 0

		while (i < this.openingHours.length && !openingHours) {

			if (this.openingHours[i].id === openingHoursId) {
				openingHours = this.openingHours[i]
			}

			i += 1
		}

	}

	return openingHours

}

export default Restaurant
