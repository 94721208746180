/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import card from '@waiterio/styles/card'


export default ({children, className}) => {

	return <div
		className={className}
		css={card}
	>
		{children}
	</div>

}
