export default function (string) {

	if (string) {

		if (
			string.toLowerCase() === string ||
			string.toUpperCase() === string
		) {
			string = string.split(' ').map(string =>

				string.charAt(0).toUpperCase() + string.substring(1).toLowerCase(),

			).join(' ')
		}

	}

	return string

}
