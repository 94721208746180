import getBrowserLanguage from '@waiterio/shared/getBrowserLanguage'

export default function () {

	return {
		data: {},
		language: getBrowserLanguage(),
		pages: {
			landing: {},
			login: {},
			signup: {},
			info: {},
			searchRestaurant: {},
		},
		session: {},
		translations: {},
	}

}
