import { createBrowserHistory } from 'history'

let history

export default () => {
	if (!history) {
		history = createBrowserHistory()
	}

	return history
}
