import getCurrentRestaurantId from '@waiterio/builder/session/getCurrentRestaurantId'
import clearSession from '@waiterio/builder/session/clearSession'

export default function isLoggedInSession () {

	let isLoggedIn = false

	const currentRestaurantId = getCurrentRestaurantId()

	const validCurrentRestaurantId = currentRestaurantId && currentRestaurantId.length > 0

	if (validCurrentRestaurantId) {
		isLoggedIn = true
	}

	if (!isLoggedIn) {
		clearSession()
	}

	return isLoggedIn

}
