/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/core'

import { connect, produce } from '@waiterio/context'

import * as RestaurantSelectors from '@waiterio/builder/selectors/RestaurantSelectors'

import translate from '@waiterio/builder/translate'

import getRestaurant from '@waiterio/builder/getRestaurant'
import updateRestaurant from '@waiterio/builder/services/updateRestaurant'

import LayoutComponent from '@waiterio/builder/components/LayoutComponent'
import SetupRestaurantNavBar from '@waiterio/builder/components/SetupRestaurantNavBar'
import Dropdown from '@waiterio/builder/components/Dropdown'

import LoadingDialog from '@waiterio/builder/dialogs/LoadingDialog'
import ErrorDialog from '@waiterio/builder/dialogs/ErrorDialog'


import getBrowserHistory from '../getBrowserHistory'

const errorMessage = css({
	display:'flex',
	flex:1,
	alignItems:'center',
	justifyContent:'center',
	height:160,
	fontSize:36,
	color:'red',
	padding: 36,
})

const semiDivider = css({
	height: 1,
	marginLeft: 8,
	marginRight: 8,
	backgroundColor: '#e8e8e8',
})

class SetRestaurantServicesPage extends Component {

	componentDidMount = async () => {

		try {

			let { restaurant } = this.props

			if (!restaurant) {

				let { restaurantId } = this.props

				this.props.produce(draft => {
					draft.loading = true
				})

				const restaurant = await getRestaurant(restaurantId)

				produce(draft => {
					draft.data.restaurant = restaurant
				})

				this.props.produce(draft => {
					draft.loading = false
				})

			}


		} catch (error) {

			this.props.produce(draft => {
				draft.loading = false
				draft.error = error && error.message
			})

		}

	}

	showErrorDialog = error => {
		this.props.produce(draft => {
			delete draft.loadingDialog
			draft.errorDialog = error ? error.toString() : null
		})
	}

	hideErrorDialog = () => {
		this.props.produce(draft => { delete draft.errorDialog })
	}

	showLoadingDialog = () => {
		this.props.produce(draft => {
			draft.loadingDialog = true
		})
	}

	hideLoadingDialog = () => {
		this.props.produce(draft => { delete draft.loadingDialog })
	}

	onChangeService = (type, selectedValue) => {

		let { restaurant } = this.props

		restaurant = restaurant.set(type, selectedValue)

		produce(draft => {
			draft.data.restaurant = restaurant
		})

		this.props.produce(draft => {
			draft.hasRestaurantChanged = true
		})

	}

	onNext = event => {

		event.preventDefault()

		this.showLoadingDialog()

		const { restaurant } = this.props

		updateRestaurant(restaurant)
			.then(response => {

				this.hideLoadingDialog()
				getBrowserHistory().push('/builder/info')

			})
			.catch(error => {

				this.hideLoadingDialog()
				this.showErrorDialog(error)

			})

	}

	skipRestaurantServicesSetup = () => {
		getBrowserHistory().push('/builder/info')
	}

	render () {

		const {
			restaurant = {},
			deliveryServiceOfferedLabels,
			deliveryServiceOfferedValues,
			tableServiceOfferedLabels,
			tableServiceOfferedValues,
			takeawayServiceOfferedLabels,
			takeawayServiceOfferedValues,
			hasRestaurantChanged,
			loadingDialog,
			errorDialog,
			loading,
			error,
		} = this.props

		const {
			deliveryServiceOffered,
			takeawayServiceOffered,
			tableServiceOffered,
		} = restaurant

		return (
			<>

				{loading && <div css={{marginBottom:30}} className="loader" />}

				{!loading && restaurant &&
					<LayoutComponent>
						<SetupRestaurantNavBar
							title={'Restaurant services'}
							backToPage={'address'}
						/>
						<div
							css={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',
								alignItems: 'center',
								height: '100%',
								width: '100%',
								backgroundColor: 'rgb(220, 225, 227)',
							}}
						>
							<div css={{margin: '16px auto', padding: '0px 16px', maxWidth: 400, width: '100%'}}>
								<div css={{boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.24), 0 0.5px 2px rgba(0, 0, 0, 0.19)'}}>
									<div css={{display: 'flex', backgroundColor: 'white'}}>
										<h4 css={{marginLeft: 8}}>{translate('Restaurant services')}</h4>
									</div>
									<div css={{width:'100%',height:1,backgroundColor:'#e4e4e4'}} />
									<div css={{height: 0.5, backgroundColor: '#e8e8e8'}} />
									<form onSubmit={this.onNext} css={{width: '100%', backgroundColor: 'white'}}>
										<Dropdown
											name={translate('Delivery')}
											onSelect={selectedValue => this.onChangeService('deliveryServiceOffered', selectedValue)}
											currentValue={deliveryServiceOffered}
											labels={deliveryServiceOfferedLabels}
											values={deliveryServiceOfferedValues}
										/>
										<Dropdown
											name={translate('Takeaway')}
											onSelect={selectedValue => this.onChangeService('takeawayServiceOffered', selectedValue)}
											currentValue={takeawayServiceOffered}
											labels={takeawayServiceOfferedLabels}
											values={takeawayServiceOfferedValues}
										/>
										<Dropdown
											name={translate('Table')}
											onSelect={selectedValue => this.onChangeService('tableServiceOffered', selectedValue)}
											currentValue={tableServiceOffered}
											labels={tableServiceOfferedLabels}
											values={tableServiceOfferedValues}
										/>
										<div css={semiDivider} />
										<button
											type="submit"
											css={{
												padding: 14,
												border: 0,
												margin: 0,
												width: '100%',
												fontSize: 18,
												fontWeight: 'bold',
												backgroundColor: hasRestaurantChanged ? 'var(--color-secondary)' : 'var(--color-secondary-lightest)',
												color: 'white',
												cursor: 'pointer',
												textTransform: 'uppercase',
												pointerEvents: !hasRestaurantChanged && 'none',
												':hover': {
													backgroundColor: 'var(--color-secondary-light)',
												},
											}}
										>
											{translate('Next')}
										</button>

									</form>
								</div>
							</div>
						</div>

						{loadingDialog && <LoadingDialog title={translate('Loading')} message={translate('Updating restaurant')} />}
						{errorDialog && <ErrorDialog message={errorDialog} close={this.hideErrorDialog} />}

						{error &&
							<div css={errorMessage}>
								{error}
							</div>
						}

					</LayoutComponent>
				}

			</>
		)
	}
}

export default connect((context, props, produce) => {

	const {
		loadingDialog = false,
		errorDialog = false,
		loading = false,
		hasRestaurantChanged = false,
		error = '',
	} = context.pages.info

	const restaurantId = context.session.currentRestaurantId
	const { restaurant } = context.data

	const deliveryServiceOfferedLabels = RestaurantSelectors.deliveryServiceOfferedLabels(context)
	const deliveryServiceOfferedValues = RestaurantSelectors.deliveryServiceOfferedValues(context)
	const tableServiceOfferedLabels = RestaurantSelectors.tableServiceOfferedLabels(context)
	const tableServiceOfferedValues = RestaurantSelectors.tableServiceOfferedValues(context)
	const takeawayServiceOfferedLabels = RestaurantSelectors.takeawayServiceOfferedLabels(context)
	const takeawayServiceOfferedValues = RestaurantSelectors.takeawayServiceOfferedValues(context)

	return {
		restaurantId,
		restaurant,
		deliveryServiceOfferedLabels,
		deliveryServiceOfferedValues,
		takeawayServiceOfferedLabels,
		takeawayServiceOfferedValues,
		tableServiceOfferedLabels,
		tableServiceOfferedValues,
		hasRestaurantChanged,
		loadingDialog,
		errorDialog,
		loading,
		error,
		produce: produce(['pages', 'info']),
	}

})(SetRestaurantServicesPage)
