import failure from '@waiterio/api-client/failure'

export default function failureSignup (response) {

	if (response && response.status === 409) {
		response.message = 'The email address is already in use'
	} else if (response && response.status === 429) {
		response.message = 'A restaurant is already present at your location. Provide your email to your colleagues whom can invite you from the Staff page of the Waiterio app.'
	}

	return failure(response)

}
