import Immutable from 'seamless-immutable'
import UUID from './UUID'

function OpeningHours (source) {

	if (source instanceof OpeningHours) {

		return source

	} else {

		source && Object.keys(source).map(function (key) {
			this[key] = source[key]
		}.bind(this))

		if (!this.id) {
			this.id = UUID.generate()
		}

		if (!this.creationTime) {
			this.creationTime = new Date().getTime()
		}

		if (!this.lastEditTime) {
			this.lastEditTime = this.creationTime
		}

		return Immutable(this, {prototype: OpeningHours.prototype})

	}

}

OpeningHours.prototype.constructor = OpeningHours

OpeningHours.IS_OPEN = 'IS_OPEN'

OpeningHours.Days = {
	DAY_0: 0,
	DAY_1: 1,
	DAY_2: 2,
	DAY_3: 3,
	DAY_4: 4,
	DAY_5: 5,
	DAY_6: 6,
}

OpeningHours.Days.getAll = function () {

	return [
		OpeningHours.Days.DAY_0,
		OpeningHours.Days.DAY_1,
		OpeningHours.Days.DAY_2,
		OpeningHours.Days.DAY_3,
		OpeningHours.Days.DAY_4,
		OpeningHours.Days.DAY_5,
		OpeningHours.Days.DAY_6,
	]

}

export const { Days } = OpeningHours

OpeningHours.prototype.getDayInText = function () {
	const { open } = this.timings[0]
	const { day } = open
	return OpeningHours.getDayInText(day)
}

OpeningHours.getDayInText = function (_day) {
	return OpeningHours.DaysInText[_day].charAt(0) + OpeningHours.DaysInText[_day].slice(1).toLowerCase()
}

export const { getDayInText } = OpeningHours

OpeningHours.DaysInText = [
	'SUNDAY',
	'MONDAY',
	'TUESDAY',
	'WEDNESDAY',
	'THURSDAY',
	'FRIDAY',
	'SATURDAY',
]

export const { DaysInText } = OpeningHours

OpeningHours.getLocalizeTime = function (time, language, countryCode) {
	
	const hours = time.slice(0, 2)
	const minutes = time.slice(2, 4)
	const openingHoursDate = new Date()
	openingHoursDate.setHours(hours)
	openingHoursDate.setMinutes(minutes)

	return openingHoursDate.toLocaleTimeString(
		`${language}-${countryCode}`,
		{hour: '2-digit', minute: '2-digit'},
	)

}

export const { getLocalizeTime } = OpeningHours

export default OpeningHours
