import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AuthenticationEnforcer from './components/AuthenticationEnforcer'
import RehydratorComponent from './components/RehydratorComponent'
import LandingPage from './pages/LandingPage'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import InfoPage from './pages/InfoPage'
import FindOnGoogleMapsPage from './pages/FindOnGoogleMapsPage'
import SetRestaurantNamePage from './pages/SetRestaurantNamePage'
import SetRestaurantAddressPage from './pages/SetRestaurantAddressPage'
import SetRestaurantServicesPage from './pages/SetRestaurantServicesPage'

const Routes = () => {

	return <Switch>

		<Redirect exact from="/" to="/builder" />
		<Route exact path="/builder" render={() => <LandingPage />} />
		<Route exact path="/builder/signup" render={() => <SignupPage />} />
		<Route exact path="/builder/login" render={() => <LoginPage />} />
		<AuthenticationEnforcer>
			<RehydratorComponent>
				<Route exact path="/builder/info" render={() => <InfoPage />} />
				<Route
					exact
					path="/builder/find-on-google-maps"
					render={() => <FindOnGoogleMapsPage />}
				/>
				<Route exact path="/builder/info/restaurant-name" render={() => <SetRestaurantNamePage />} />
				<Route exact path="/builder/info/restaurant-address" render={() => <SetRestaurantAddressPage />} />
				<Route exact path="/builder/info/restaurant-services" render={() => <SetRestaurantServicesPage />} />
			</RehydratorComponent>
		</AuthenticationEnforcer>

	</Switch>

}

export default Routes
