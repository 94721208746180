let token = null

export function getToken () {

	return token

}

export function setToken (token_) {

	token = btoa(token_)

}
