function LoginRequest (source) {

	source && Object.keys(source).map(function (key) {
		this[key] = source[key]
	}.bind(this))

	if (!this.expireTime) {
		this.expireTime = 0
	}

}

LoginRequest.prototype.constructor = LoginRequest

LoginRequest.BASIC_AUTHENTICATION 		= 'Basic '
LoginRequest.PIN_AUTHENTICATION 		= 'Pin '
LoginRequest.FOURSQUARE_AUTHENTICATION 	= 'Foursquare '
LoginRequest.FACEBOOK_AUTHENTICATION 	= 'Facebook '
LoginRequest.GOOGLE_AUTHENTICATION 		= 'Google '
LoginRequest.TWITTER_AUTHENTICATION 	= 'Twitter '

export default LoginRequest
