import translate from '@waiterio/builder/translate'
import Android_Toast from '@waiterio/builder/services/Android_Toast'

export const toast = message => {

	let content = translate(message)

	content = content && content.replace(new RegExp('\\n', 'g'), '<br>')
	console.log('creating toast')
	return new Android_Toast({content})

}

export const toastError = error => {

	let message = error && error.toString && error.toString()
	message = message && message.length ? message : 'An error occurred'

	return toast(message)

}
