function ApiError (source) {

	if (source && typeof source === 'object') {

		source && Object.keys(source).map(function (key) {
			this[key] = source[key]
		}.bind(this))

		if (typeof navigator !== 'undefined' && !navigator.onLine) {

			this.message = 'No Internet connection'

		} else if (source.message === 'Failed to fetch') {

			this.message = 'A network error occurred'

		} else if (source.message) {

			this.message = source.message

		} else {

			if (source.status) {
				this.message += source.status

				if (source.body) {
					this.message += ' '
				}

			}

			if (source.body) {

				if (typeof source.body === 'object') {

					if (source.body.string) {
						this.message += source.body.string
					} else {
						this.message += JSON.stringify(source.body)
					}

				} else {

					this.message += source.body

				}

			}

		}

	} else if (source && typeof source === 'string') {

		this.message = source

	} else {

		this.message = ''

	}

	this.name = 'ApiError'

}

ApiError.prototype = Error.prototype
ApiError.prototype.toString = function () {

	return this.message

}

export default ApiError
