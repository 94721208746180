function NotFoundError (message) {

	this.message = message
	this.status = 404
	this.name = 'NotFoundError'

}

NotFoundError.prototype = Error.prototype
NotFoundError.prototype.toString = function () {

	return this.message

}

export default NotFoundError
