import fetch from 'isomorphic-fetch'
import getApiUrl from '@waiterio/env/getApiUrl'
import failure from '@waiterio/api-client/failure'
import httpWithoutHeaders from '@waiterio/api-client/httpWithoutHeaders'
import { getToken } from '@waiterio/api-client/token'

export default function http (config, customFailure) {

	if (!config.headers) {
		config.headers = {}
	}

	config.headers.Accept = config.headers.Accept || 'application/json'
	config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json'
	config.headers.Authorization = config.headers.Authorization || 'Token ' + getToken()

	return fetch(getApiUrl() + '/' + config.url, {

		method: config.method,
		headers: config.headers,
		body: JSON.stringify(config.body),

	}).then(function (response) {

		if (!response.ok) {

			if (response.url.startsWith('https://s3.amazonaws.com')) {

				return httpWithoutHeaders(response.url)

			} else {

				return response.json().then((error = {}) => {

					if (!error.status) {
						error.status = response.status
					}

					if (!error.message) {
						error.message = response.statusText
					}

					throw error

				})

			}

		} else {

			return response.json()

		}

	}).catch(customFailure || failure)

}
