/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import IconSend from '@waiterio/components/IconSend'
import IconBack from '@waiterio/components/IconBack'
import IconClose from '@waiterio/components/IconClose'
import IconSearch from '@waiterio/components/IconSearch'
import getRestaurant from '@waiterio/builder/getRestaurant'
import Restaurant from '@waiterio/model/Restaurant'
import countries from '@waiterio/shared/countries'
import deleteNulls from '@waiterio/builder/deleteNulls'

import getBrowserHistory from '@waiterio/builder/getBrowserHistory'
import { connect, produce } from '@waiterio/context'

import updateRestaurant from '@waiterio/builder/services/updateRestaurant'

import translate from '@waiterio/builder/translate'

import LayoutComponent from '@waiterio/builder/components/LayoutComponent'


class FindOnGoogleMapsPage extends PureComponent {

	autoCompleteRef = React.createRef()

	googleMapRef = React.createRef()

	map = {}

	componentDidMount = async () => {

		try {

			let { restaurant } = this.props

			if (!restaurant) {

				let { restaurantId } = this.props

				this.props.produce(draft => {
					draft.loading = true
				})

				let restaurant = await getRestaurant(restaurantId)

				restaurant = deleteNulls(restaurant)

				produce(draft => {
					draft.data.restaurant = restaurant
				})

				this.props.produce(draft => {
					draft.loading = false
				})

			}

			const googlePlacesScriptElement = document.createElement('script')
			googlePlacesScriptElement.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyArbBpwLeOP_q07C4US9vNqlTYnSQd2Aac&libraries=places'
			window.document.body.appendChild(googlePlacesScriptElement)

			googlePlacesScriptElement.addEventListener('load', () => {
				this.startGoogleMap()
				this.startAutoComplete()
			})


		} catch (error) {

			this.props.produce(draft => {
				draft.loading = false
				draft.error = error && error.message
			})

		}

	}

	startGoogleMap = () => {

		this.getCoordinatesFromAddress()
			.then(coordinates => {

				this.map = this.createGoogleMap(coordinates)
				this.createMapMarker(this.map, coordinates)

			}).catch(error => {

				console.log('error', error)

			})

	}

	startAutoComplete = () => {

		const { countryCode } = this.props.restaurant

		const options = {
			types: ['establishment'],
		}

		if (countryCode) {
			options.componentRestrictions = { country: countryCode }
		}

		const autoComplete = new window.google.maps.places.Autocomplete(

			this.autoCompleteRef.current,
			options,

		)

		autoComplete.setFields(['name', 'place_id', 'formatted_address'])

		autoComplete.addListener('place_changed', () =>
			this.handlePlaceSelect(autoComplete),
		)

	}

	redrawGoogleMap = updatedAddress => {

		this.getCoordinatesFromAddress(updatedAddress)
			.then(coordinates => {

				this.map = this.createGoogleMap(coordinates)
				this.createMapMarker(this.map, coordinates)

			})
	}

	getCoordinatesFromAddress = updatedAddress => {

		const { restaurant = {} } = this.props
		let address = updatedAddress

		const {
			street,
			city,
			zipcode,
			state,
			countryCode,
		} = restaurant

		if (!updatedAddress) {
			address = `${street && street.trim() + ',' || ''}${zipcode && zipcode.trim() + ',' || ''}${city && city.trim() + ',' || ''}${state && state.trim() + ',' || ''}${countryCode && (countries[countryCode] ?? countryCode) || ''}`
		}

		return new Promise((resolve, reject) => {

			const geocoder = new window.google.maps.Geocoder()
			geocoder.geocode({address}, function (results, status) {

				if (status === 'OK') {

					const { location } = results[0].geometry

					const coordinates = {
						lat: location.lat(),
						lng: location.lng(),
					}

					resolve(coordinates)

				} else {

					reject(status)

				}

			})

		})

	}

	createGoogleMap = coordinates => {

		return new window.google.maps.Map(this.googleMapRef.current, {
			zoom: 16,
			center: coordinates,
		})

	}

	createMapMarker = (map, coordinates) => {

		return new window.google.maps.Marker({
			map,
			position: coordinates,
		})

	}

	onChangeSearch = event => {

		const inputText = event.target.value

		this.props.produce(draft => {
			draft.searchQuery = inputText
		})

	}

	handlePlaceSelect = async autoComplete => {

		const { restaurant } = this.props
		const { name, place_id, formatted_address } = autoComplete.getPlace()

		let newRestaurant = new Restaurant(restaurant)
		newRestaurant = newRestaurant.set('googlePlaceId', place_id)

		this.props.produce(draft => {
			draft.searchQuery = `${name}, ${formatted_address}`
			draft.hasChanged = newRestaurant
		})

		this.redrawGoogleMap(formatted_address)

	}

	save = () => {

		const { restaurant, hasChanged } = this.props

		updateRestaurant(hasChanged, restaurant)
			.then(updatedRestaurant => {
				produce(draft => {
					draft.data.restaurant.googlePlaceId = updatedRestaurant.restaurant.googlePlaceId
				})
			})
			.catch(error => {
				console.log('error', error)
			})

		this.goBack()

	}

	goBack = () => {
		getBrowserHistory().goBack()
	}

	onCancelSearch = () => {
		this.props.produce(draft => {
			draft.searchQuery = ''
		})
	}

	componentWillUnmount = () => {
		this.props.produce()
	}

	render () {

		const { searchQuery, hasChanged } = this.props

		return (

			<LayoutComponent>
				<div css={{position:'fixed', top:0, left:0, width:'100%', zIndex:1000, height:56, backgroundColor: 'var(--color-primary)'}}>
					<div onClick={this.goBack} css={{width:250, display:'flex', alignItems:'center', height:'100%', cursor:'pointer', ':hover': {backgroundColor: 'var(--color-primary-light)'}}}>
						<IconBack css={{marginLeft: 16, marginRight: 16, width: 24, height: 24, fill:'white'}} />
						<span css={{fontSize:18, color:'white', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
							{translate('Find on Google Maps')}
						</span>
					</div>
				</div>

				<div css={{height:56,minHeight:56}} />

				<div css={{height:56, backgroundColor: 'var(--color-primary)', display:'flex',justifyContent:'center', alignItems:'center',paddingBottom:18}}>
					<label ref={element => { this.searchBarInput = element }} css={{position:'relative',boxShadow: '0px 1.5px 4px rgba(0,0,0,0.24)', backgroundColor: 'white', display: 'flex', justifyContent:'space-between', maxWidth:450, width:'100%',cursor:'text',margin:'14.5px'}}>
						<IconSearch
							css={{maxWidth: 24, width: '100%', margin: 'auto 0px auto 14px', fill: 'var(--color-primary)'}}
						/>
						<input
							ref={this.autoCompleteRef}
							onChange={this.onChangeSearch}
							value={searchQuery || ''}
							placeholder={'Ex. California Pizza, Rashid Minhas Rd, Block 10-A Block 10 A Gulshan-E-Iqbal, Karachi, Karachi City, Sindh, Pakistan'}
							onFocus={this.onChange}
							onKeyDown={this.onKeyPress}
							css={{
								textTransform: 'capitalize',
								padding: 14,
								width: 350,
								border: 0,
								outline: 'none',
							}}
							autoFocus
						/>

						<IconClose
							css={{maxWidth: 20, width: '100%', margin: 'auto 14px auto 0px', opacity: searchQuery ? 0.5 : 0 , cursor: searchQuery ? 'pointer' : 'text' }}
							onClick={this.onCancelSearch}
						/>

					</label>
				</div>

				<div
					ref={this.googleMapRef}
					css={{maxHeight: 800, height: '100%', width: '100%', margin: 'auto'}}
				/>

				{hasChanged &&
					<div
						css={{
							backgroundColor: 'var(--color-secondary)',
							position:'fixed',
							bottom:0,
							left:0,
							width:'100%',
							zIndex: 999,
							height:64,
							border:0,
							color:'white',
							display: 'flex',
							justifyContent: 'space-between',
							cursor: 'pointer',
							':hover': {
								backgroundColor: 'var(--color-secondary-lighter)',
							},
						}}
						onClick={this.save}
						data-automation="save"
					>
						<IconSend css={{marginLeft: 20, width: 24, height: 24, alignSelf:'center',textAlign:'center',fill:'white'}} />
						<span css={{fontSize: 18, fontWeight: 'bold', color: 'white', alignSelf:'center',textTransform:'uppercase'}}>{translate('Send')}</span>
						<div css={{width: 50}} />
					</div>
				}

			</LayoutComponent>
		)

	}

}

export default connect((context, props, produce) => {

	let { searchQuery, loading = false, error = false, hasChanged } = context.pages.searchRestaurant
	const restaurantId = context.session.currentRestaurantId
	const { restaurant } = context.data

	return {
		searchQuery,
		hasChanged,
		restaurant,
		restaurantId,
		loading,
		error,
		produce: produce(['pages', 'searchRestaurant']),
	}

})(FindOnGoogleMapsPage)
