import Immutable from 'seamless-immutable'

function Settings (source) {

	if (source instanceof Settings) {

		return source

	} else {

		source && Object.keys(source).map(function (key) {
			this[key] = source[key]
		}.bind(this))

		if (!this.showTips) {
			this.showTips = Settings.ShowTips.NEVER
		}

		if (this.showSuggestions === undefined) {
			this.showSuggestions = true
		}

		if (this.ratedAndroidApp === undefined) {
			this.ratedAndroidApp = false
		}

		if (!this.sortOrdersBy) {
			this.sortOrdersBy = Settings.SortOrdersBy.TABLE_NAME
		}

		if (this.showOnlyMyOrders === undefined) {
			this.showOnlyMyOrders = false
		}

		if (this.hidePaidOrders === undefined) {
			this.hidePaidOrders = true
		}

		if (!this.hideOrdersAutomatically) {
			this.hideOrdersAutomatically = Settings.HideOrdersAutomatically.AT_6AM
		}

		if (!this.ordersLayou) {
			this.ordersLayout = Settings.OrdersLayout.MULTI_COLUMN_VIEW
		}

		return Immutable(this, {prototype: Settings.prototype})

	}

}

Settings.prototype.constructor = Settings

Settings.ShowTips = {
	NEVER: 'NEVER',
	JUST_NEXT_TIME: 'JUST_NEXT_TIME',
	ALWAYS: 'ALWAYS',
}

Settings.ShowTips.getAll = function () {

	return [
		Settings.ShowTips.NEVER,
		Settings.ShowTips.JUST_NEXT_TIME,
		Settings.ShowTips.ALWAYS,
	]

}

export const { ShowTips } = Settings

Settings.SortOrdersBy = {
	CREATION_TIME_ASCENDING: 'CREATION_TIME_ASCENDING',
	CREATION_TIME_DESCENDING: 'CREATION_TIME_DESCENDING',
	LAST_EDIT_TIME_ASCENDING: 'LAST_EDIT_TIME_ASCENDING',
	LAST_EDIT_TIME_DESCENDING: 'LAST_EDIT_TIME_DESCENDING',
	TABLE_NAME: 'TABLE_NAME',
}

Settings.SortOrdersBy.getAll = function () {

	return [
		Settings.SortOrdersBy.CREATION_TIME_ASCENDING,
		Settings.SortOrdersBy.CREATION_TIME_DESCENDING,
		Settings.SortOrdersBy.LAST_EDIT_TIME_ASCENDING,
		Settings.SortOrdersBy.LAST_EDIT_TIME_DESCENDING,
		Settings.SortOrdersBy.TABLE_NAME,
	]

}

export const { SortOrdersBy } = Settings

Settings.HideOrdersAutomatically = {

	AFTER_1_HOUR: 'AFTER_1_HOUR',
	AFTER_3_HOURS: 'AFTER_3_HOURS',
	AFTER_6_HOURS: 'AFTER_6_HOURS',
	AFTER_12_HOURS: 'AFTER_12_HOURS',
	AFTER_24_HOURS: 'AFTER_24_HOURS',
	AT_6AM: 'AT_6AM',

}

Settings.HideOrdersAutomatically.getAll = function () {

	return [
		Settings.HideOrdersAutomatically.AFTER_1_HOUR,
		Settings.HideOrdersAutomatically.AFTER_3_HOURS,
		Settings.HideOrdersAutomatically.AFTER_6_HOURS,
		Settings.HideOrdersAutomatically.AFTER_12_HOURS,
		Settings.HideOrdersAutomatically.AFTER_24_HOURS,
		Settings.HideOrdersAutomatically.AT_6AM,
	]

}

export const { HideOrdersAutomatically } = Settings

Settings.OrdersLayout = {
	SINGLE_COLUMN_VIEW: 'SINGLE_COLUMN_VIEW',
	MULTI_COLUMN_VIEW: 'MULTI_COLUMN_VIEW',
}

Settings.OrdersLayout.getAll = function () {

	return [
		Settings.OrdersLayout.SINGLE_COLUMN_VIEW,
		Settings.OrdersLayout.MULTI_COLUMN_VIEW,
	]

}

export const { OrdersLayout } = Settings

export default Settings
