/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'

import AppBar from '@waiterio/components/AppBar'
import IconBack from '@waiterio/components/IconBack'

import translate from '@waiterio/builder/translate'

import clickable from '@waiterio/builder/styles/clickable'

import getBrowserHistory from '../getBrowserHistory'

function onSkip (url) {
	getBrowserHistory().push(`/builder/info${url || ''}`)
}

const SetupRestaurantNavBar = ({ title, backToPage, skipToPageUrl }) =>
	<AppBar>
		<div css={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
			<Link
				to={`/builder/info/restaurant-${backToPage}`}
				css={[
					clickable,
					{
						display: 'flex',
						alignItems: 'center',
						textDecoration: 'none',
						pointerEvents: !backToPage && 'none',
					},
				]}
			>
				<IconBack
					css={{
						fill: 'white',
						margin: '0 16px',
					}}
				/>
				<div
					css={{
						marginRight: 16,
						fontSize: 18,
						color: 'white',
					}}
				>
					{translate(title)}
				</div>
			</Link>
			<div
				onClick={() => onSkip(skipToPageUrl)}
				css={[
					clickable,
					{
						display: 'flex',
						alignItems: 'center',
						padding: 16,
					},
				]}
			>
				<span css={{fontSize: 18}}>{translate('Skip')}</span>
			</div>
		</div>
	</AppBar>

export default SetupRestaurantNavBar
