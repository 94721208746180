import User from '@waiterio/model/User'
import LoginRequest from '@waiterio/model/LoginRequest'
import clearSession from '@waiterio/builder/session/clearSession'

import { setToken as setTokenRest } from '@waiterio/api-client/token'
import loginRest from '@waiterio/api-client/login'
import signupRest from '@waiterio/api-client/signup'

let waiterioToken

export async function signup (email, password, pin, firstName, lastName) {

	reset()
	clearSession()

	let loginRequest = createLoginRequest({
		authenticationType: LoginRequest.BASIC_AUTHENTICATION,
		authenticationCredentials: btoa(email + ':' + password + ':' + pin),
		user: {
			email,
			firstName,
			lastName,
		},
	})

	const loginResponse = await signupRest(loginRequest)
	onLoginSucceed(loginResponse)

	return loginResponse

}

export function createLoginRequest (loginRequest) {

	return new LoginRequest({
		...loginRequest,
		user: new User({
			...loginRequest.user,
			// TODO country: Locale.getDefault().getCountry(),
			// TODO inviteToken: ReferrerBroadcastReceiver.getReferrer(waiterioApplication),
		}),
	})

}

export async function loginWithEmail (email, password) {

	reset()
	clearSession()

	let loginRequest = createLoginRequest({
		authenticationType: LoginRequest.BASIC_AUTHENTICATION,
		authenticationCredentials: btoa(email + ':' + password),
	})

	const loginResponse = await loginRest(loginRequest)
	onLoginSucceed(loginResponse)

	return loginResponse

}

export async function onLoginSucceed (loginResponse) {

	waiterioToken = loginResponse.waiterioToken
	// inviteToken = loginResponse.inviteToken

	setTokenRest(loginResponse.waiterioToken)

}

export function reset () {
	waiterioToken = null
}
