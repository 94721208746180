import { css } from '@emotion/core'

export default css`
	label: clickable;
	user-select: none;
	cursor: default;
	outline: none;

	@media (min-width: 993px) {
		&:not([disabled]):hover,
		&:not([disabled]):active {
			background: var(--color-primary-light);
			cursor: pointer;
		}
	}
`
