import React, { PureComponent } from 'react'

export default class IconSend extends PureComponent {

	render = () => {

		const {className, width = 24, height = 24, onClick, style} = this.props
		
		return (
			<svg className={className} style={style} width={width} height={height} viewBox="0 0 24 24" onClick={onClick}>
				<path d="M2.016 21l20.976-9-20.976-9-0.024 7.008 15 1.992-15 1.992z" />
			</svg>
		)

	}

}
