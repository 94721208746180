/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import elevated from '@waiterio/styles/elevated'


export default ({children}) =>
	<div
		css={[
			elevated,
			{
				background:'var(--color-primary)',
				color: 'white',
				fill: 'white',
				height: 56,
				display: 'flex',
			},
		]}
	>
		{children}
	</div>
