/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import ENVIRONMENT from '@waiterio/env/ENVIRONMENT'
import STAGING from '@waiterio/env/STAGING'
import DEVELOPMENT from '@waiterio/env/DEVELOPMENT'
import PRODUCTION from '@waiterio/env/PRODUCTION'
import IS_SCREENSHOTS from '@waiterio/env/IS_SCREENSHOTS'

const StageBadge = () => {

	let environment = ENVIRONMENT
	let background = 'transparent'
	let color = 'transparent'

	// environment = STAGING

	if (environment === DEVELOPMENT) {
		environment = 'DEV'
		background = 'yellow'
		color = 'black'
	} else if (environment === STAGING) {
		environment = 'STAGE'
		background = 'blue'
		color = 'white'
	}

	return (ENVIRONMENT !== PRODUCTION && !IS_SCREENSHOTS) &&
		<div css={{color,background,textAlign:'center',position:'fixed',left:'-32px',bottom:'-32px',opacity:'0.6',width:64,height:64,transform:'rotate(45deg)',lineHeight:'16px',fontSize:12,fontWeight:'bold',textTransform:'uppercase'}}>
			{environment}
		</div>

}

export default StageBadge
