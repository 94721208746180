/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import translate from '@waiterio/builder/translate'
import Modal from '@waiterio/components/Modal'

export default class FieldStringDialog extends PureComponent {

	constructor (props) {
		super(props)

		this.state = {
			label: props.label,
			value: props.value,
		}

	}

	onChange = event => {
		this.setState({ value: event.target.value })
	}

	onSubmit = event => {

		event.preventDefault()
		this.props.onSave(this.state.label, this.state.value)
		this.props.close()

	}

	render = () => {

		return (

			<Modal close={this.props.close}>
				<form onSubmit={this.onSubmit}>
					<div
						css={{
							width: '100%',
							textAlign: 'center',
							padding: 14,
							backgroundColor: 'var(--color-primary)',
							color: 'white',
							fontSize: 18,
						}}
					>
						{translate(this.state.label)}
					</div>
					<input
						placeholder={translate(this.state.label)}
						css={{fontSize: 18, width:'100%', outline: 'none', padding: 14, border: 0}}
						value={this.state.value || ''}
						onChange={this.onChange}
						required
						autoFocus
						data-automation="input"
					/>
					<button
						type="submit"
						css={{
							width: '100%',
							padding: 14,
							fontSize: 18,
							border: 0,
							backgroundColor: 'white',
							':hover': {
								backgroundColor: 'var(--color-primary)',
								filter: 'brightness(130%)',
								color: 'white',
								cursor: 'pointer',
							},
						}}
					>
						Save
					</button>
				</form>
			</Modal>

		)

	}

}
