import { createSelector } from 'reselect'
import fetchTranslation from '../fetchTranslation'

const translations = context => context.translations
const browserLanguage = context => context.language
const currentUser = context => context.session.currentUser

export const currentUserSelector = createSelector(
	[currentUser],
	user => user,
)

export const languageSelector = createSelector(
	[currentUserSelector, browserLanguage],
	(user, browserLanguage) => user && user.language || browserLanguage,
)

export default createSelector(
	[translations, languageSelector],
	(translations = {}, locale) => {

		locale = locale !== 'in' ? locale : 'id'

		if (locale && !translations[locale]) {
			fetchTranslation(locale)
		}

		return translations[locale]

	},
)
