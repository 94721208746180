import { createSelector } from 'reselect'
import translate from '@waiterio/builder/translate'

const restaurant = context => context.data.restaurant

export const currentRestaurantSelector = createSelector(

	[restaurant],
	restaurant => {

		return restaurant

	},

)

export const deliveryServiceOfferedValues = createSelector(

	[currentRestaurantSelector],
	restaurant => typeof restaurant?.deliveryServiceOffered === 'undefined' ? [undefined, true, false] : [true, false],
)

export const deliveryServiceOfferedLabels = createSelector(

	[currentRestaurantSelector],
	restaurant => {

		let values = ['Yes', 'No']

		if (typeof restaurant?.deliveryServiceOffered === 'undefined') {
			values = ['?', 'Yes', 'No']
		}

		return values.map(value => translate(value).toLowerCase())

	},

)

export const tableServiceOfferedValues = createSelector(

	[currentRestaurantSelector],
	restaurant => typeof restaurant?.tableServiceOffered === 'undefined' ? [undefined, true, false] : [true, false],
)

export const tableServiceOfferedLabels = createSelector(

	[currentRestaurantSelector],
	restaurant => {

		let values = ['Yes', 'No']

		if (typeof restaurant?.tableServiceOffered === 'undefined') {
			values = ['?', 'Yes', 'No']
		}

		return values.map(value => translate(value).toLowerCase())

	},

)

export const takeawayServiceOfferedValues = createSelector(

	[currentRestaurantSelector],
	restaurant => typeof restaurant?.takeawayServiceOffered === 'undefined' ? [undefined, true, false] : [true, false],
)

export const takeawayServiceOfferedLabels = createSelector(

	[currentRestaurantSelector],
	restaurant => {

		let values = ['Yes', 'No']

		if (typeof restaurant?.takeawayServiceOffered === 'undefined') {
			values = ['?', 'Yes', 'No']
		}

		return values.map(value => translate(value).toLowerCase())

	},

)
