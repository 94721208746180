import { produce } from '@waiterio/context'
import IS_BROWSER from '@waiterio/env/IS_BROWSER'


export default async function fetchTranslation (locale) {

	let translation

	if (IS_BROWSER) {
		translation = await import(/* webpackChunkName: "locale-[request]" */ `./locale/${locale}`)
			.catch(console.error)
	}

	if (translation) {
		produce(draft => {

			if (!draft.translations[locale]) {
				draft.translations[locale] = {}
			}

			draft.translations[locale] = translation

		})
	}

}
