import IS_DEVELOPMENT from './IS_DEVELOPMENT'
import IS_STAGING from './IS_STAGING'
import IS_TESTING from './IS_TESTING'
import IS_BROWSER from './IS_BROWSER'
import IS_NODE from './IS_NODE'

export default function () {

	let url = 'https://api.waiterio.com/api/v3'

	if (IS_BROWSER && window.WAITERIO_API_URL && window.WAITERIO_API_URL !== '{{{WAITERIO_API_URL}}}') {

		url = `${window.WAITERIO_API_URL}/api/v3`

	} else if (IS_NODE && process.env.WAITERIO_API_URL) {

		url = `${process.env.WAITERIO_API_URL}/api/v3`

	} else if (IS_STAGING) {

		url = 'https://api.waiterio-staging.com/api/v3'

	} else if (IS_TESTING && process.env.WAITERIO_API_URL) {

		url = `${process.env.WAITERIO_API_URL}/api/v3`

	} else if (IS_DEVELOPMENT || IS_TESTING) {

		// url = 'https://api.waiterio-staging.com/api/v3';
		// url = 'http://192.168.1.64:3000/api/v3';
		url = 'http://localhost:3000/api/v3'

	}

	// if (IS_STAGING || IS_DEVELOPMENT) {
	// 	console.debug('Api.url = ' + url)
	// }

	return url

}
