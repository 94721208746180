import { css } from '@emotion/core'

export default css({
	WebkitTouchCtextout: 'none',
	WebkitUserSelect: 'none',
	KhtmlUserSelect: 'none',
	MozUserSelect: 'none',
	MsUserSelect: 'none',
	userSelect: 'none',
	cursor: 'default',
})
