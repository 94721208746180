import NotFoundError from '@waiterio/errors/NotFoundError'
import queryGraphQL from '@waiterio/fetch/queryGraphQL'

import deleteNulls from '@waiterio/builder/deleteNulls'
import Restaurant from '@waiterio/model/Restaurant'

export default _id => {
	return queryGraphQL(`{
		restaurant: getRestaurant(_id: "${_id}") {
			_id
			shortId
			vanityId
			name
			description
			email
			website
			theme
			city
			countryCode
			street
			state
			zipcode
			phone
			deliveryServiceOffered
			reservationsOffered
			tableServiceOffered
			takeawayServiceOffered
			facebookId
			foursquareId
			googlePlaceId
			instagramId
			tripadvisorId
			whatsapp
			coverUrl
		}
	}`).then(restaurant => {

		if (restaurant) {

			return deleteNulls(restaurant)

		} else {

			throw new NotFoundError('restaurant not found')

		}

	}).then(restaurant => new Restaurant(restaurant))

}
