/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/core'

import { connect, produce } from '@waiterio/context'
import translate from '@waiterio/builder/translate'

import getRestaurant from '@waiterio/builder/getRestaurant'
import updateRestaurant from '@waiterio/builder/services/updateRestaurant'

import LayoutComponent from '@waiterio/builder/components/LayoutComponent'
import SetupRestaurantNavBar from '@waiterio/builder/components/SetupRestaurantNavBar'

import LoadingDialog from '@waiterio/builder/dialogs/LoadingDialog'
import ErrorDialog from '@waiterio/builder/dialogs/ErrorDialog'

import countries from '@waiterio/shared/countries'

import getBrowserHistory from '../getBrowserHistory'

const errorMessage = css({
	display:'flex',
	flex:1,
	alignItems:'center',
	justifyContent:'center',
	height:160,
	fontSize:36,
	color:'red',
	padding: 36,
})

const formField = css({
	fontSize: 18,
	width: '100%',
	paddingRight: 8,
	height: 48,
	outline: 'none',
	color: 'var(--color-base)',
	border: 0,
	borderRadius: 0,
	textAlign: 'right',
	'::placeholder': {
		color: 'rgb(195, 195, 195)',
	},
})

const formLabel = css({
	fontSize: 18,
	paddingLeft: 8,
	paddingRight: 8,
	display: 'flex',
	alignItems: 'center',
})

const semiDivider = css({
	height: 1,
	marginLeft: 8,
	marginRight: 8,
	backgroundColor: '#e8e8e8',
})

class SetRestaurantAddressPage extends Component {

	countryDropDownRef = React.createRef()

	componentDidMount = async () => {

		document.addEventListener('mousedown', this.onClickOutside)

		try {

			let { restaurant } = this.props

			if (!restaurant) {

				let { restaurantId } = this.props

				this.props.produce(draft => {
					draft.loading = true
				})

				const restaurant = await getRestaurant(restaurantId)

				const { countryCode } = restaurant

				const countryNameByCode = Object.values(countries).find(countryName => {
					return countries[countryCode] === countryName
				})

				produce(draft => {
					draft.data.restaurant = restaurant
				})

				this.props.produce(draft => {
					draft.loading = false
					draft.selectedCountry = countryNameByCode
				})

			}


		} catch (error) {

			this.props.produce(draft => {
				draft.loading = false
				draft.error = error && error.message
			})

		}

	}

	onClickOutside = event => {
		if (this.countryDropDownRef.current && !this.countryDropDownRef.current.contains(event.target)) {
			this.hideCountryDropdown()
		}
	}

	onSearchCountry = event => {

		const inputValue = event.target.value

		this.props.produce(draft => {
			draft.countrySearch = inputValue
		})

	}

	onChangeInput = (type, event) => {

		const inputValue = event.target.value
		this.onChangeRestaurant(type, inputValue)

	}

	onChangeRestaurant = (type, value) => {

		let { restaurant } = this.props
		restaurant = restaurant.set(type, value)

		produce(draft => {
			draft.data.restaurant = restaurant
		})

		this.props.produce(draft => {
			draft.hasRestaurantChanged = true
		})

	}

	showErrorDialog = error => {
		this.props.produce(draft => {
			delete draft.loadingDialog
			draft.errorDialog = error ? error.toString() : null
		})
	}

	hideErrorDialog = () => {
		this.props.produce(draft => { delete draft.errorDialog })
	}

	showLoadingDialog = () => {
		this.props.produce(draft => {
			draft.loadingDialog = true
		})
	}

	hideLoadingDialog = () => {
		this.props.produce(draft => { delete draft.loadingDialog })
	}

	onNext = event => {

		event.preventDefault()

		this.showLoadingDialog()

		const { restaurant } = this.props

		updateRestaurant(restaurant)
			.then(response => {

				this.hideLoadingDialog()
				getBrowserHistory().push('/builder/info/restaurant-services')

			})
			.catch(error => {

				this.hideLoadingDialog()
				this.showErrorDialog(error)

			})
	}

	showCountryDropdown = () => {

		this.props.produce(draft => {
			draft.isCountryDropdownVisible = true
		})

	}

	hideCountryDropdown = () => {

		this.props.produce(draft => {
			draft.isCountryDropdownVisible = false
			draft.countrySearch = ''
		})

	}

	onChangeCountry = countryName => {

		const selectedCountryCode = Object.keys(countries).find(countryCode => {
			return countries[countryCode] === countryName
		})

		this.props.produce(draft => {
			draft.selectedCountry = countryName
		})

		this.onChangeRestaurant('countryCode', selectedCountryCode)

		this.hideCountryDropdown()

	}

	onKeyDown = event => {

		if (event.key === 'Escape') {

			this.hideCountryDropdown()

		}

	}

	componentWillUnMount = () => {
		document.removeEventListener('mousedown', this.onClickOutside)
	}

	skipRestaurantAddressSetup = () => {
		getBrowserHistory().push('/builder/info/restaurant-services')
	}

	render () {

		const {
			restaurant = {},
			selectedCountry,
			countrySearch,
			isCountryDropdownVisible,
			hasRestaurantChanged,
			loadingDialog,
			errorDialog,
			loading,
			error,
		} = this.props

		const {
			state = '',
			city = '',
			zipcode = '',
			street = '',
		} = restaurant

		return (
			<>

				{loading && <div css={{marginBottom:30}} className="loader" />}

				{!loading && restaurant &&

					<LayoutComponent>
						<SetupRestaurantNavBar
							title={'Restaurant address'}
							backToPage={'name'}
							skipToPageUrl={'/restaurant-services'}
						/>
						<div
							css={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',
								alignItems: 'center',
								height: '100%',
								width: '100%',
								backgroundColor: 'rgb(220, 225, 227)',
							}}
						>
							<div css={{margin: '16px auto', padding: '0px 16px',maxWidth: 500, width: '100%'}}>
								<div css={{boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.24), 0 0.5px 2px rgba(0, 0, 0, 0.19)'}}>
									<div css={{display: 'flex', backgroundColor: 'white'}}>
										<h4 css={{marginLeft: 8}}>{translate('Restaurant address')}</h4>
									</div>
									<div css={{width:'100%',height:1,backgroundColor:'#e4e4e4'}} />
									<form onSubmit={this.onNext} css={{width: '100%', backgroundColor: 'white'}}>
										<div
											css={{
												display: 'flex',
												position: 'relative',
												'@media (min-width: 993px)': {
													':hover > input, :hover > label': {
														backgroundColor: 'var(--color-primary-lightest)',
													},
												},
											}}
											ref={this.countryDropDownRef}
										>
											<label
												htmlFor="countryInput"
												css={formLabel}
											>
												{translate('country')}
											</label>
											<input
												id="countryInput"
												css={[formField, {'::placeholder': {color: 'var(--color-base)'}}]}
												type="text"
												placeholder={selectedCountry}
												value={countrySearch}
												onFocus={this.showCountryDropdown}
												onChange={event => {
													this.onSearchCountry(event)
													this.showCountryDropdown()
												}}
												onKeyDown={this.onKeyDown}
												autoComplete="new-password"
												required={selectedCountry && false}
											/>
											{isCountryDropdownVisible &&
												<CountryDropdown
													countrySearch={countrySearch}
													onChangeCountry={this.onChangeCountry}
													hideCountryDropdown={this.hideCountryDropdown}
												/>
											}
										</div>
										<div css={semiDivider} />
										<div
											css={{
												display: 'flex',
												'@media (min-width: 993px)': {
													':hover > input, :hover > label': {
														backgroundColor: 'var(--color-primary-lightest)',
													},
												},
											}}
										>
											<label
												htmlFor="stateInput"
												css={formLabel}
											>
												{translate('state')}
											</label>
											<input
												id="stateInput"
												css={formField}
												type="text"
												value={state}
												onChange={event => this.onChangeInput('state', event)}
												required={false}
											/>
										</div>
										<div css={semiDivider} />
										<div
											css={{
												display: 'flex',
												'@media (min-width: 993px)': {
													':hover > input, :hover > label': {
														backgroundColor: 'var(--color-primary-lightest)',
													},
												},
											}}
										>
											<label
												htmlFor="cityInput"
												css={formLabel}
											>
												{translate('city')}
											</label>
											<input
												id="cityInput"
												css={formField}
												type="text"
												value={city}
												onChange={event => this.onChangeInput('city', event)}
												required
											/>
										</div>
										<div css={semiDivider} />
										<div
											css={{
												display: 'flex',
												'@media (min-width: 993px)': {
													':hover > input, :hover > label': {
														backgroundColor: 'var(--color-primary-lightest)',
													},
												},
											}}
										>
											<label
												htmlFor="zipcodeInput"
												css={formLabel}
											>
												{translate('zipcode')}
											</label>
											<input
												id="zipcodeInput"
												css={formField}
												type="text"
												value={zipcode}
												onChange={event => this.onChangeInput('zipcode', event)}
												required={false}
											/>
										</div>
										<div css={semiDivider} />
										<div
											css={{
												display: 'flex',
												'@media (min-width: 993px)': {
													':hover > input, :hover > label': {
														backgroundColor: 'var(--color-primary-lightest)',
													},
												},
											}}
										>
											<label
												htmlFor="streetInput"
												css={formLabel}
											>
												{translate('street')}
											</label>
											<input
												id="streetInput"
												css={formField}
												type="text"
												value={street}
												onChange={event => this.onChangeInput('street', event)}
												required
											/>
										</div>

										<button
											type="submit"
											css={{
												padding: 14,
												border: 0,
												margin: 0,
												width: '100%',
												fontSize: 18,
												fontWeight: 'bold',
												backgroundColor: hasRestaurantChanged ? 'var(--color-secondary)' : 'var(--color-secondary-lightest)',
												pointerEvents: !hasRestaurantChanged && 'none',
												color: 'white',
												cursor: 'pointer',
												textTransform: 'uppercase',
												':hover': {
													backgroundColor: 'var(--color-secondary-light)',
												},
											}}
										>
											{translate('Next')}
										</button>

									</form>
								</div>
							</div>
						</div>

						{error &&
							<div css={errorMessage}>
								{error}
							</div>
						}

						{loadingDialog && <LoadingDialog title={translate('Loading')} message={translate('Updating restaurant')} />}
						{errorDialog && <ErrorDialog message={errorDialog} close={this.hideErrorDialog} />}


					</LayoutComponent>

				}

			</>
		)
	}
}

const CountryDropdown = ({ countrySearch, onChangeCountry }) => {

	const filteredCountryNames = Object.values(countries).filter(country => {
		return country.match(new RegExp(countrySearch, 'gi'))
	})

	return (

		<div
			css={{
				position: 'absolute',
				top: '100%',
				left: 0,
				maxHeight: 400,
				width: '100%',
				zIndex: 1000,
				overflowY: 'auto',
				backgroundColor: 'white',
				border: '1px solid #e8e8e8',
				borderTop: 0,
			}}
		>
			{filteredCountryNames &&
				filteredCountryNames.map(countryName =>
					<div
						key={countryName}
						onClick={() => onChangeCountry(countryName)}
						css={{
							padding: 10,
							textAlign: 'right',
							':hover': {
								backgroundColor: 'var(--color-primary-lightest)',
								cursor: 'pointer',
							},
						}}
					>
						{countryName}
					</div>,
				)
			}
		</div>
	)
}

export default connect((context, props, produce) => {

	const {
		selectedCountry = '',
		countrySearch = '',
		isCountryDropdownVisible = false,
		hasRestaurantChanged = false,
		loadingDialog = false,
		errorDialog = false,
		loading = false,
		error = false,
	} = context.pages.info

	const restaurantId = context.session.currentRestaurantId
	const { restaurant } = context.data

	return {
		restaurantId,
		restaurant,
		selectedCountry,
		countrySearch,
		isCountryDropdownVisible,
		hasRestaurantChanged,
		loadingDialog,
		errorDialog,
		loading,
		error,
		produce: produce(['pages', 'info']),
	}

})(SetRestaurantAddressPage)
