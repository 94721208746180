/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import normalizeEmail from '@waiterio/shared/normalizeEmail'
import preferCapitalized from '@waiterio/shared/preferCapitalized'
import translate from '@waiterio/builder/translate'
import getBrowserHistory from '@waiterio/builder/getBrowserHistory'
import { connect } from '@waiterio/context'

import * as loginManager from '@waiterio/builder/services/LoginManager'
import storeNewSession from '@waiterio/builder/session/storeNewSession'

import IconBack from '@waiterio/components/IconBack'
import AppBar from '@waiterio/components/AppBar'
import LoadingDialog from '@waiterio/builder/dialogs/LoadingDialog'
import ErrorDialog from '@waiterio/builder/dialogs/ErrorDialog'
import LayoutComponent from '../components/LayoutComponent'

const formField = css({
	fontSize: 18,
	width: '100%',
	paddingRight: 8,
	height: 48,
	outline: 'none',
	color: 'var(--color-base)',
	border: 0,
	borderRadius: 0,
	textAlign: 'right',
	'::placeholder': {
		color: 'rgb(195, 195, 195)',
	},
})

const formLabel = css({
	fontSize: 18,
	paddingLeft: 8,
	paddingRight: 8,
	display: 'flex',
	alignItems: 'center',
})

const semiDivider = css({
	height: 1,
	marginLeft: 8,
	marginRight: 8,
	backgroundColor: '#e8e8e8',
})

class SignupPage extends React.Component {

	reset = () => {
		this.props.produce()
	}

	showError = error => {

		this.props.produce(draft => {
			draft.signing = false
			draft.error = error
		})

	}

	hideError = () => {
		this.props.produce(draft => {
			delete draft.error
		})
	}

	onChangeInput = (type, event) => {

		const inputValue = event.target.value

		this.props.produce(draft => {
			draft[type] = inputValue
		})

	}

	onSignup = event => {

		event.preventDefault()

		this.props.produce(draft => {
			draft.signing = true
		})

		let { email, password, pin, firstName, lastName } = this.props

		email = normalizeEmail(email?.trim())
		firstName = preferCapitalized(firstName?.trim())
		lastName = preferCapitalized(lastName?.trim())

		return loginManager.signup(email, password, pin, firstName, lastName)
			.then(this.onSignupSucceed)
			.catch(error => {
				this.showError(error.toString())
			})

	}

	onSignupSucceed = signupResponse => {

		this.props.produce(draft => {
			draft.signing = false
		})

		storeNewSession(signupResponse)

		getBrowserHistory().push('/builder/info')

	}

	componentWillUnmount = () => {
		this.reset()
	}

	render () {

		const {
			email,
			password,
			pin,
			firstName,
			lastName,
			signing,
			error,
		} = this.props

		return (
			<LayoutComponent>
				<AppBar>
					<Link
						to="/builder"
						css={{
							display: 'flex',
							alignItems: 'center',
							textDecoration: 'none',
							':hover': {
								color: 'white',
								background: 'var(--color-primary-light)',
							},
						}}
					>
						<IconBack
							css={{
								fill: 'white',
								margin: '0 16px',
							}}
						/>
						<div
							css={{
								marginRight: 16,
								fontSize: 18,
							}}
						>
							{translate('Sign up')}
						</div>
					</Link>
				</AppBar>

				<div css={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', backgroundColor: 'rgb(220, 225, 227)'}}>

					<div
						css={{
							maxWidth: 400,
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							marginTop: 14,
							paddingLeft: 14,
							paddingRight: 14,
							border: 0,
						}}
					>
						<form onSubmit={this.onSignup} css={{width: '100%', backgroundColor: 'white'}}>
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="emailInput"
									css={formLabel}
								>
									{translate('email')}
								</label>
								<input
									id="emailInput"
									css={formField}
									type="email"
									value={email}
									onChange={event => this.onChangeInput('email', event)}
									autoComplete="email"
									autoCapitalize="none"
									autoCorrect="off"
									required
								/>
							</div>
							<div css={semiDivider} />
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="passwordInput"
									css={formLabel}
								>
									{translate('password')}
								</label>
								<input
									id="passwordInput"
									css={formField}
									type="password"
									value={password}
									onChange={event => this.onChangeInput('password', event)}
									autoComplete="current-password"
									autoCapitalize="none"
									autoCorrect="off"
									minLength="6"
									required
								/>
							</div>

							<div css={semiDivider} />
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="pinInput"
									css={formLabel}
								>
									{translate('PIN')}
								</label>
								<input
									id="pinInput"
									css={formField}
									placeholder="1234"
									type="tel"
									value={pin}
									onChange={event => this.onChangeInput('pin', event)}
									autoComplete="current-pin"
									autoCapitalize="none"
									autoCorrect="off"
									pattern="[0-9]*"
									minLength="4"
									maxLength="4"
									required
								/>
							</div>

							<div css={semiDivider} />
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="firstNameInput"
									css={[formLabel, {flexShrink: 0}]}
								>
									{translate('first name')}
								</label>
								<input
									id="firstNameInput"
									css={formField}
									type="text"
									value={firstName}
									onChange={event => this.onChangeInput('firstName', event)}
									autoComplete="given-name"
									autoCorrect="off"
									required
								/>
							</div>

							<div css={semiDivider} />
							<div
								css={{
									display: 'flex',
									'@media (min-width: 993px)': {
										':hover > input, :hover > label': {
											backgroundColor: 'var(--color-primary-lightest)',
										},
									},
								}}
							>
								<label
									htmlFor="lastNameInput"
									css={[formLabel, {flexShrink: 0}]}
								>
									{translate('last name')}
								</label>
								<input
									id="lastNameInput"
									css={formField}
									type="text"
									value={lastName}
									onChange={event => this.onChangeInput('lastName', event)}
									autoComplete="family-name"
									autoCorrect="off"
									required
								/>
							</div>


							<button
								type="submit"
								css={{
									padding: 14,
									border: 0,
									margin: 0,
									width: '100%',
									fontSize: 18,
									fontWeight: 'bold',
									backgroundColor: 'var(--color-secondary)',
									color: 'white',
									cursor: 'pointer',
									textTransform: 'uppercase',
									':hover': {
										backgroundColor: 'var(--color-secondary-light)',
									},
								}}
							>
								{translate('Sign up')}
							</button>

						</form>

						<Link
							to="/builder/login"
							css={{
								fontSize: 14,
								fontWeight: 'bold',
								width: '100%',
								padding: 14,
								textAlign: 'center',
								cursor: 'pointer',
								textDecoration: 'none',
								textTransform: 'uppercase',
								':hover': {
									color: 'var(--color-base)',
									backgroundColor: 'var(--color-primary-lightest)',
								},
							}}
						>
							{translate('Login')}
						</Link>

					</div>

					{signing &&
						<LoadingDialog
							title={translate('Signing up')}
							message={translate('logging in waiterio')}
						/>
					}

					{error &&
						<ErrorDialog
							title={translate('Error')}
							message={
								error || translate('An error occurred')
							}
							close={this.hideError}
						/>
					}

				</div>
			</LayoutComponent>
		)
	}

}

export default connect((context, props, produce) => {

	const {
		email = '',
		password = '',
		pin = '',
		firstName = '',
		lastName = '',
		signing = false,
		error = false,
	} = context.pages.signup

	return {
		email,
		password,
		pin,
		firstName,
		lastName,
		signing,
		error,
		produce: produce(['pages', 'signup']),
	}

})(SignupPage)
