export default function (file) {

	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.onload = event => resolve(event.target.result)
		fileReader.onerror = () => reject(new Error('Error while reading file'))
		fileReader.readAsDataURL(file)
	})

}
