export default function deleteNulls (obj) {

	let isArray = obj instanceof Array

	Object.keys(obj).forEach(key => {

		if (obj[key] === null) isArray ? obj.splice(key, 1) : delete obj[key]
		else if (typeof obj[key] === 'object') deleteNulls(obj[key])
		if (isArray && obj.length === key) deleteNulls(obj)

	})

	return obj

}
