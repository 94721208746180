import React from 'react'
import { Helmet } from 'react-helmet'
import { darken, lighten } from 'polished'
import green from '@waiterio/styles/green'
import violetBrandColor from '@waiterio/styles/violetBrandColor'


const colorPrimary = violetBrandColor
const colorPrimaryDark = darken(0.08, colorPrimary)
const colorPrimaryDarker = darken(0.16, colorPrimary)
const colorPrimaryDarkest = darken(0.24, colorPrimary)
const colorPrimaryLight = lighten(0.08, colorPrimary)
const colorPrimaryLighter = lighten(0.16, colorPrimary)
const colorPrimaryLightest = lighten(0.24, colorPrimary)


const colorSecondary = green
const colorSecondaryLight = lighten(0.08, colorSecondary)
const colorSecondaryLighter = lighten(0.16, colorSecondary)
const colorSecondaryLightest = lighten(0.24, colorSecondary)


export default ({ children }) => {

	const title = 'Website Builder | Waiterio'
	const description = 'Build a website for your restaurant'

	return <>

		<Helmet>
			<title>{title}</title>
			<meta
				name="description"
				content={description}
			/>
			<meta property="og:title" content={title} />
			<meta property="og:site_name" content={title} />
			<meta
				property="og:description"
				content={description}
			/>

			<meta name="application-name" content={title} />
			<meta name="theme-color" content="var(--color-primary)" />

		</Helmet>
		<style>{`
			:root {
				--color-base: #626262;

				--color-primary: ${colorPrimary};
				--color-primary-dark: ${colorPrimaryDark};
				--color-primary-darker: ${colorPrimaryDarker};
				--color-primary-darkest: ${colorPrimaryDarkest};
				--color-primary-light: ${colorPrimaryLight};
				--color-primary-lighter: ${colorPrimaryLighter};
				--color-primary-lightest: ${colorPrimaryLightest};

				--color-secondary: ${colorSecondary};
				--color-secondary-light: ${colorSecondaryLight};
				--color-secondary-lighter: ${colorSecondaryLighter};
				--color-secondary-lightest: ${colorSecondaryLightest};
			}
		`}</style>
		<>
			{children}
		</>
	</>
}
