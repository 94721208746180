import React from 'react'
import { hydrate } from 'react-dom'
import { Router } from 'react-router-dom'
import getSentryBrowser from '@waiterio/telemetry/getSentryBrowser'
import ErrorBoundaryGlobal from '@waiterio/components/ErrorBoundaryGlobal'
import ScrollToTop from '@waiterio/components/ScrollToTop'
import getBrowserHistory from './getBrowserHistory'
import getInitialContext from './getInitialContext'
import App from './App'

const initialContext = window.__PRELOADED_CONTEXT__ || getInitialContext()

hydrate(
	<ErrorBoundaryGlobal>
		<Router history={getBrowserHistory()}>
			<ScrollToTop />
			<App initialContext={initialContext} />
		</Router>
	</ErrorBoundaryGlobal>,
	document.getElementById('root'),
	(/* a,b,c */) => {

		getSentryBrowser()

	},
)
