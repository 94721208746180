import * as Sentry from '@sentry/browser'
import ENVIRONMENT from '@waiterio/env/ENVIRONMENT'
import IS_DEVELOPMENT from '@waiterio/env/IS_DEVELOPMENT'
import IS_TESTING from '@waiterio/env/IS_TESTING'

let sentry

export default function getSentryBrowser () {

	if (
		!sentry &&
		!IS_DEVELOPMENT &&
		!IS_TESTING
	) {

		let dsn

		if (typeof window !== 'undefined') {
			dsn = window.SENTRY_DSN

			if (!dsn) {
				console.warn('Missing window.SENTRY_DSN')
			}
		}

		Sentry.init({
			dsn,
			environment: ENVIRONMENT,
		})

		sentry = Sentry

	}

	return sentry

}
