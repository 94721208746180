import IS_DEVELOPMENT from '@waiterio/env/IS_DEVELOPMENT'

export default function getBrowserLanguage () {

	let language

	if (typeof window !== 'undefined') {

		language = window?.navigator?.languages?.[0]

		if (!language || !language.length) {
			language = window?.navigator?.language || window?.navigator?.userLanguage
		}

		if (language === 'fil') {
			language = 'tl'
		}

		if (language && language.length >= 2) {
			language = language.substring(0, 2)
		}

		if (language === 'in') {
			language = 'id'
		} else if (language === 'ji') {
			language = 'yi'
		} else if (language === 'jw') {
			language = 'jv'
		} else if (language === 'iw') {
			language = 'he'
		} else if (language === 'nb' || language === 'nn') {
			language = 'no'
		}

		if (IS_DEVELOPMENT) {
			// language = 'fr'
		}
	}

	return language

}
