/** @jsx jsx */
import React from 'react'
import { Link } from 'react-router-dom'
import { jsx, css } from '@emotion/core'
import { connect } from '@waiterio/context'
import IconPapillon from '@waiterio/components/IconPapillon'
import translate from '@waiterio/builder/translate'
import LayoutComponent from '../components/LayoutComponent'

const shadow = css`
	text-shadow: -0.5px -0.5px 0 var(--color-primary-darkest), 0.5px -0.5px 0 var(--color-primary-darkest), -0.5px 0.5px 0 var(--color-primary-darkest), 0.5px 0.5px 0 var(--color-primary-darkest);
`

const violetButton = css`
	display: block;
	border: 0;
	height: 48px;
	line-height: 48px;
	padding: 0 16px;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	box-sizing: border-box;
	border-width: 1px !important;
	border-color: white !important;
	border-style: solid !important;
	border-radius: 5px !important;
	box-shadow: none !important;
	text-decoration: none;
	width: 50%;
	margin: 5px;
	&:not(.disabled):hover {
		background-color: white;
		color: var(--color-primary);
	}
	&:not(.disabled):active {
		background-color: var(--color-primary-lighter);
		color: white;
	}
`

const LandingPage = ({ translate }) =>
	<LayoutComponent>
		<div css={{flexGrow:1,display:'flex',flexDirection:'column',alignItems:'stretch',height:'100%',backgroundColor:'var(--color-primary)',width:'100vw',color:'white',zIndex:1}}>

			<div css={[shadow,{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',fontSize:60,paddingTop:20,zIndex:1}]}>
				<IconPapillon width="60" height="60" css={{fill:'white'}} />
			</div>
			<div css={{flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center',zIndex:1,maxWidth:'48ch',margin:'0 auto',padding:'0 14.5px 60px 14.5px'}}>
				<div css={[shadow,{textAlign:'center',fontSize:'6vmin',fontWeight:'bold',marginTop: 20}]}>{translate('Build a website for your restaurant')}</div>
			</div>
			<div css={[shadow,{display:'flex',alignItems:'stretch',width:'100%',maxWidth:500,margin:'0 auto',padding:5,boxSizing:'border-box',zIndex:1}]}>
				<Link css={violetButton} to={'/builder/signup'} data-automation="signup">{translate('Sign up')}</Link>
				<Link css={violetButton} to={'/builder/login'} data-automation="login">{translate('Login')}</Link>
			</div>
		</div>
	</LayoutComponent>

export default connect((context, props, produce) => {

	return {
		translate,
	}

})(LandingPage)
