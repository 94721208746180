import IS_DEVELOPMENT from './IS_DEVELOPMENT'
import IS_STAGING from './IS_STAGING'
import IS_TESTING from './IS_TESTING'
import IS_BROWSER from './IS_BROWSER'
import IS_NODE from './IS_NODE'

export default function () {

	let url = 'https://graphql.waiterio.com/graphql'

	if (IS_BROWSER && window.WAITERIO_GRAPHQL_URL && window.WAITERIO_GRAPHQL_URL !== '{{{WAITERIO_GRAPHQL_URL}}}') {

		url = window.WAITERIO_GRAPHQL_URL + '/graphql'

	} else if (IS_NODE && process.env.WAITERIO_GRAPHQL_URL) {

		url = process.env.WAITERIO_GRAPHQL_URL + '/graphql'

	} else if (IS_STAGING) {

		url = 'https://graphql.waiterio-staging.com/graphql'

	} else if (IS_TESTING && process.env.WAITERIO_GRAPHQL_URL) {

		url = process.env.WAITERIO_GRAPHQL_URL + '/graphql'

	} else if (IS_DEVELOPMENT || IS_TESTING) {

		// url = 'https://graphql.waiterio-staging.com/graphql';
		// url = 'http://192.168.1.62:7000/graphql';
		// url = 'http://192.168.43.127:7000/graphql';
		// url = 'http://10.9.41.134:7000/graphql';
		url = 'http://localhost:7000/graphql'

	}

	// if (IS_STAGING || IS_DEVELOPMENT) {
	// 	console.debug('Graphql.url = ' + url)
	// }

	return url

}
