/* eslint no-var: 0 */

const STAGING = require('./STAGING')
const PRODUCTION = require('./PRODUCTION')
const TESTING = require('./TESTING')
const IS_BROWSER = require('./IS_BROWSER')
const IS_NODE = require('./IS_NODE')

var environment = 'development'

if (typeof process !== 'undefined' && process.env.JEST_WORKER_ID) {

	environment = TESTING

} else if (IS_BROWSER) {

	if (window.location.hostname.indexOf('staging') !== -1) {

		environment = STAGING

	} else if (window.location.hostname.indexOf('.waiterio.com') !== -1) {

		environment = PRODUCTION

	}

} else if (IS_NODE && process.env.WAITERIO_ENV) {

	environment = process.env.WAITERIO_ENV

} else if (IS_NODE && process.env.NODE_ENV) {

	environment = process.env.NODE_ENV

}

module.exports = environment
