import { produce } from '@waiterio/context'
import getCurrentRestaurantId from '@waiterio/builder/session/getCurrentRestaurantId'
import getCurrentUser from '@waiterio/builder/session/getCurrentUser'

export default async function rehydrate () {

	const currentRestaurantId = await getCurrentRestaurantId()
	const currentUser = await getCurrentUser()

	produce(draft => {
		draft.session.currentRestaurantId = currentRestaurantId
		draft.session.currentUser = currentUser
		draft.session.rehydrated = true
	})

}
