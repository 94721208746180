import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import isLoggedInSession from '@waiterio/builder/session/isLoggedInSession'

const AuthenticationEnforcer = ({children}) =>

	isLoggedInSession() ? children : <Redirect to="/builder/login" />


export default withRouter(AuthenticationEnforcer)
