import getGraphqlUrl from '@waiterio/env/getGraphqlUrl'
import fetch from './index'

export default (queryString, variables) => {

	return fetch(getGraphqlUrl(), {
		method: 'POST',
		body: JSON.stringify({
			query: queryString,
			variables,
		}),
	})
		.then(response => {

			let result = response

			if (result.data) {

				result = response.data

				let resultKeys = Object.keys(result)

				if (resultKeys.length === 1) {
					result = result[resultKeys[0]]
				}

			} else if (result.errors) {
				throw result
			}

			return result

		})
		.catch(error => {

			if (error.errors) {

				error.message = error.errors.reduce((allErrors, error) => {

					if (error && error.message) {
						allErrors += ' ' + error.message
					}

					return allErrors

				}, error.message ? error.message : '')

			}

			throw error

		})

}
