/** @jsx jsx */
import React, { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import { connect } from '@waiterio/context'


class RehydratorComponent extends PureComponent {

	render () {

		const { rehydrated, children } = this.props

		return <>
			{rehydrated && children}
			{!rehydrated && <div className="loader" />}
		</>

	}

}

export default connect((context, props) => {

	const { rehydrated } = context.session

	return {
		rehydrated,
	}

})(RehydratorComponent)
