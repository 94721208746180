import Immutable from 'seamless-immutable'
import UUID from './UUID'

function PaymentMethod (source) {

	if (source instanceof PaymentMethod) {

		return source

	} else {

		source && Object.keys(source).map(function (key) {
			this[key] = source[key]
		}.bind(this))

		if (!this.id) {
			this.id = UUID.generate()
		}

		if (!this.creationTime) {
			this.creationTime = new Date().getTime()
		}

		if (!this.lastEditTime) {
			this.lastEditTime = this.creationTime
		}

		return Immutable(this, {prototype: PaymentMethod.prototype})

	}

}

PaymentMethod.prototype.constructor = PaymentMethod

PaymentMethod.prototype.getLabel = function () {

	return PaymentMethod.getLabel(this.type)

}

PaymentMethod.getLabel = function (_type) {

	return _type.replace('_PAYMENT_METHOD', '').split('_').join(' ').toLowerCase().replace(/\b./g, a => a.toUpperCase())

}

PaymentMethod.CASH_PAYMENT_METHOD		 = 	'CASH_PAYMENT_METHOD'
PaymentMethod.CHEQUE_PAYMENT_METHOD 	 = 	'CHEQUE_PAYMENT_METHOD'
PaymentMethod.CREDIT_CARD_PAYMENT_METHOD = 	'CREDIT_CARD_PAYMENT_METHOD'
PaymentMethod.CUSTOM_PAYMENT_METHOD 	 = 	'CUSTOM_PAYMENT_METHOD'
PaymentMethod.VOUCHER_PAYMENT_METHOD 	 = 	'VOUCHER_PAYMENT_METHOD'

PaymentMethod.getTypes = function () {

	return [
		PaymentMethod.CASH_PAYMENT_METHOD,
		PaymentMethod.CREDIT_CARD_PAYMENT_METHOD,
		PaymentMethod.CHEQUE_PAYMENT_METHOD,
		PaymentMethod.VOUCHER_PAYMENT_METHOD,
		PaymentMethod.CUSTOM_PAYMENT_METHOD,
	]
}

export default PaymentMethod
