import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from '@waiterio/context'
import StageBadge from '@waiterio/components/StageBadge'
import rehydrateSession from '@waiterio/builder/session/rehydrate'
import isLoggedInSession from '@waiterio/builder/session/isLoggedInSession'
import GlobalStyles from './styles/GlobalStyles'
import Routes from './Routes'

if (isLoggedInSession()) {
	console.log('session is active')
	rehydrateSession()
}


export default ({initialContext}) => <>

	<GlobalStyles />
	<Helmet>
		<meta charset="utf-8" />
		<meta name="viewport" content="user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width" />
		<meta name="apple-mobile-web-app-capable" content="yes" />
		<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
		<meta name="mobile-web-app-capable" content="yes" />
	</Helmet>
	<Provider initialContext={initialContext}>
		<Routes />
	</Provider>
	<StageBadge />
</>
