import React, { createContext, Component } from 'react'
import produceImmer from 'immer'

const path = (obj, path = []) => path.reduce((o, key) => (o && o[key] ? o[key] : null), obj)

const produceScope = produce => (first, second) => {
	let producer
	let scope

	if (first) {
		if (typeof first === 'function') {
			producer = first
		} else if (first.constructor === Array) {
			scope = first
		}
	}

	if (second) {
		if (typeof second === 'function') {
			producer = second
		} else if (second.constructor === Array) {
			scope = second
		}
	}

	if (producer && scope) {
		return produce(draft => producer(path(draft, scope)))
	} else if (scope) {
		return producer => produce(draft => {
			if (producer) {
				producer(path(draft, scope))
			} else {
				path(draft, scope.slice(0,-1))[scope.slice(-1)[0]] = {}
			}
		})
	} else {
		return produce(producer)
	}
}

const Context = createContext()
const ContextConsumer = Context.Consumer


let self
export const getContext = () => self && self.state
export const produce = produceScope(producer => self && self.setState(produceImmer(producer)))

export class MockProvider {

	constructor (props) {
		self = this
		this.state = props.initialContext || {}
		this.produce = produceScope(producer => { this.setState(produceImmer(producer)) })
		this.callback = props.callback
	}

	setState (updateState) {
		this.state = updateState(this.state)
	}

}

export class Provider extends Component {

	constructor (props) {
		super(props)
		self = this
		this.state = props.initialContext || {}
		this.produce = produceScope(producer => { this.setState(produceImmer(producer)) })
		this.callback = props.callback
	}

	componentDidMount () {
		this.callback && this.callback()
	}

	render () {
		return (
			<Context.Provider
				value={{
					state: this.state,
					produce: this.produce,
				}}
			>
				{this.props.children}
			</Context.Provider>
		)
	}
}

export const connect = (
	mapContextToProps = () => null,
) => Child => props => (
	<ContextConsumer>
		{({state, produce}) => (
			<Child
				{...mapContextToProps(
					state,
					props,
					produce,
				)}
				{...props}
			/>
		)}
	</ContextConsumer>
)

export const useContext = () => {

	const stateAndproduce = React.useContext(Context)

	if (!stateAndproduce) {
		throw new Error('Missing Context Provider')
	}

	const { state, produce } = stateAndproduce


	return [state, produce]

}
